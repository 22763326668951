import { KABSwapContract } from 'contracts';
import { KAB_TOKEN_ADDRESS, KAB_SWAP } from 'env';
import { TokenEnum } from 'services/helper';
import { depositToken } from './depositToken';

export const depositKab = async (address: string | undefined, amount: number) => {
  const tokenAddress = KAB_TOKEN_ADDRESS || '';

  const token = TokenEnum.KAB;
  const swapContractAddress = KAB_SWAP || '';
  const swapContract = KABSwapContract;
  return depositToken(address, amount, tokenAddress, token, swapContractAddress, swapContract);
};
