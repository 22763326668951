import { Link, Table, TableBody, TableCell, TableHead, TableRow, styled, TextField, MenuItem } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { userService } from 'services';
import { DateTime } from 'luxon';
import { APP_NETWORK } from 'components/NetworkBar';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useState } from 'react';
import { useSearch } from 'hooks';

const eventMap = [
  { name: 'Open Cocoon', code: 'open-cocoon' },
  { name: 'Listing', code: 'list' },
  { name: 'Sale/Purchase', code: 'sale' },
  { name: 'Unlist', code: 'unlist' },
  // { name: 'Purchase', code: 'purchase' },
];

const StyledTableCell = styled(TableCell)`
  border-color: #463024;
  color: #ccc3b5;
  @media (max-width: 800px) {
    font-size: 10px;
    background-color: #170a02cc;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  border-color: #463024;
  color: #968469;
  background-color: #170a02;
  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

const Activity = () => {
  const { address } = useSelector(profileSelector);
  const { search } = useLocation();
  const { owner } = parse(search);
  const [dataSearch, onSearchChange] = useSearch({
    limit: 100,
    address: (owner as string) ?? address ?? '',
    eventType: '',
  });
  const [eventType, setEventType] = useState(' ');

  const { data: historyData } = useQuery(
    ['userService.fetchUserHistories', dataSearch],
    ({ queryKey }) => userService.fetchUserHistories(queryKey[1]),
    {
      enabled: !!address || !!owner,
    },
  );

  return (
    <div>
      <div className='md:hidden text-color-secondary font-bold text-xl text-center mb-4'>Activities</div>
      <div className='bg-color-dark-brown md:bg-color-dark rounded-lg text-white pl-3 md:pl-16 py-3 md:py-8'>
        <div className='hidden md:block font-bold text-xl mb-3'>Activities</div>
        <TextField
          select
          className='bg-color-dark w-40 md:w-48 rounded mb-3'
          value={eventType}
          onChange={(e) => {
            setEventType(e.target.value);
            onSearchChange({ eventType: e.target.value.trim() });
          }}
        >
          <MenuItem value=' '>All events</MenuItem>
          {eventMap.map((item, index) => (
            <MenuItem key={index} value={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <div className='overflow-auto pr-3 md:pr-16' style={{ height: 485 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Event</StyledHeaderCell>
                <StyledHeaderCell>Item Type</StyledHeaderCell>
                <StyledHeaderCell>Details</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData?.histories.map((history, index) => {
                const event = eventMap.find((event) => event.code === history.event);
                const name =
                  event?.code === 'sale'
                    ? ((owner as string) ?? address ?? '').toLowerCase() === history.from?.toLowerCase()
                      ? 'Sale'
                      : 'Purchase'
                    : event?.name;

                return (
                  <TableRow key={index} className='capitalize'>
                    <StyledTableCell>{name}</StyledTableCell>
                    <StyledTableCell>{`${history.itemType} - ID:${history.tokenId}`}</StyledTableCell>
                    <StyledTableCell className='flex items-center gap-1'>
                      {DateTime.fromSeconds(history.timestamp).toFormat('dd MMM yyyy, HH:mm')}
                      {!!history.txHash && (
                        <Link
                          className='flex text-white'
                          href={`${APP_NETWORK.blockExplorerUrls}/tx/${history.txHash}`}
                          target='_blank'
                        >
                          <OpenInNew className='text-xs md:text-lg' sx={{ color: '#ccc3b5' }} />
                        </Link>
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Activity;
