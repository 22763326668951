import WalletConnectProvider from '@walletconnect/web3-provider';
import { web3 } from 'contracts';
import { store } from 'reducers';
import { signIn, signOut } from 'reducers/profile';
import { openPopupRegister } from 'reducers/register';
import { authService } from 'services';
import { validSignature } from 'utils/common';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

const isMobile = () => {
  const mobiles = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return mobiles.some((matches) => navigator.userAgent.match(matches));
};

const connectProvider = async () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: { 97: 'https://bsc-testnet.public.blastapi.io', 56: 'https://bsc-dataseed1.binance.org' },
      },
    },
  };
  const web3Modal = new Web3Modal({
    providerOptions,
    theme: 'dark',
    cacheProvider: false,
  });

  const provider = Web3.givenProvider || (await web3Modal.connect());
  provider.on('accountsChanged', () => store.dispatch(signOut()));
  provider.on('disconnect', () => store.dispatch(signOut()));
  provider.on('chainChanged', () => {
    if (isMobile()) window.location.reload();
  });

  web3.setProvider(provider);
};

const connectWallet = async () => {
  try {
    await connectProvider();
    let address;
    try {
      [address] = await web3.eth.requestAccounts();
    } catch {
      [address] = await web3.eth.getAccounts();
    }
    address = web3.utils.toChecksumAddress(address);

    const { nonce } = await authService.getNonce({ address });
    const message = `Welcome to OKG Marketplace!\n\nClick "Sign" to sign in. No password needed!\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;
    const signature = validSignature(await web3.eth.personal.sign(message, address, ''));
    const { ...info } = await authService.getToken({ addr: address, nonce, signedStr: signature });
    if (!info.registered) store.dispatch(openPopupRegister());
    store.dispatch(signIn({ address, ...info }));
    return true;
  } catch (error) {
    console.log('connectWallet', error);
    return false;
  }
};

export default {
  connectProvider,
  connectWallet,
};
