import { configureStore } from '@reduxjs/toolkit';
import profile from './profile';
import register from './register';

export const store = configureStore({
  reducer: {
    profile,
    register
  },
});

export type RootState = ReturnType<typeof store.getState>;
