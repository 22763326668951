import { Pagination, PaginationItem } from '@mui/material';

type DesignPaginationProps = {
  offset?: number;
  limit?: number;
  total?: number;
  onChange: (params: any) => void;
};

const DesignPagination = ({ offset = 0, limit = 10, total = 0, onChange }: DesignPaginationProps) => {
  return (
    <Pagination
      size='large'
      color='secondary'
      page={offset / limit + 1}
      count={Math.ceil(total / limit)}
      onChange={(event, page) => {
        if (page > 0) onChange({ offset: (page - 1) * limit });
      }}
      renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
    />
  );
};

export default DesignPagination;
