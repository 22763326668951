import { CardMedia, CircularProgress, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { erc20Contract, erc721Contract } from 'contracts';
import { PopupController } from 'models/Common';
import { CocoonType } from 'models/Cocoon';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { nftService } from 'services';
import { OKG_MARKET, OKG_TOKEN_ADDRESS } from 'env';
import { useWindowDimensions } from 'hooks';

type PopupProps = PopupController & {
  item: CocoonType;
};

const PriceField = styled(TextField)`
  margin-bottom: 17px;
  box-shadow: 0px -5px #705439;
  border-radius: 10px;
  @media (max-width: 800px) {
    margin-bottom: 8px;
  }
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-input {
    font-weight: 800;
    font-size: 20px;
    color: #463024;
    padding: 13px;
    @media (max-width: 800px) {
      padding: 4px;
      font-size: 16px;
    }
  }
`;

const PopupSellCocoon = ({ item, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { data: maxCap } = useQuery(
    ['fetchMaxCap'],
    async ({ queryKey }) => {
      const maxCap = await erc20Contract(OKG_TOKEN_ADDRESS!).methods.totalSupply().call();
      return maxCap / 10 ** 18;
    },
    { placeholderData: 10 ** 14, initialData: 10 ** 14 },
  );

  const {
    mutate: sellCocoon,
    isLoading,
    isSuccess,
  } = useMutation(
    async (price: any) => {
      const isApprovedForAll = await erc721Contract(item.contract).methods.isApprovedForAll(address, OKG_MARKET).call();
      if (!isApprovedForAll) {
        await erc721Contract(item.contract).methods.setApprovalForAll(OKG_MARKET, true).send({ from: address });
      }
      await nftService.sellCocoon({ contract: item.contract, tokenId: item.tokenId, price });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Sell cocoon successfully!', { variant: 'success' });
        if (!!onSuccess) {
          onSuccess();
        }
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Sell cocoon failed!', { variant: 'error' });
      },
    },
  );

  return (
    <div className='flex flex-col items-center text-color-secondary py-7 md:p-14' style={{ maxWidth: 988 }}>
      {isSuccess ? (
        <>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 h-24 md:h-auto' />
          <span className='text-xl md:text-3xl font-skadi font-normal md:mb-3'>LISTING COMPLETED!</span>
          <div className='text-xs md:text-xl text-center mb-4 md:mb-10 px-5 md:px-0'>
            Your item is now on the top of Marketplace.
          </div>
          <div className='w-48 md:w-96'>
            <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'} onClick={onClose}>
              VIEW ITEM
            </DesignButton>
          </div>
        </>
      ) : (
        <>
          <span className='text-xl md:text-3xl font-skadi font-normal mb-2 md:mb-6'>LISTING ITEM</span>
          <div
            className='flex flex-col md:flex-row gap-2 md:gap-16 w-full bg-color-dark mb-6'
            style={{ borderRadius: 10, padding: isMobile ? 12 : 30 }}
          >
            <CardMedia
              component='video'
              src={item.animationUri}
              style={{ width: isMobile ? 262 : 352, height: isMobile ? 262 : 352, borderRadius: 10 }}
              loop
              autoPlay
              muted
              controls={false}
              playsInline
            />
            <div className='flex flex-col text-xl justify-between'>
              <div className='flex flex-col gap-1 md:gap-3 text-xs md:text-xl mb-6 md:mb-0'>
                <div className='md:text-2xl font-bold'>{item.name}</div>
                <div>ID: {item.tokenId}</div>
              </div>
              <div>
                <Controller
                  name='price'
                  defaultValue=''
                  control={control}
                  rules={{
                    required: 'Prize is required',
                    pattern: /^\d*\.?\d*$/,
                    max: maxCap,
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <>
                      <div className='text-xs md:text-xl mb-2 md:mb-3 flex justify-between'>
                        <div>Input price</div>
                        <div style={{ color: '#FF613F' }}>{error?.type === 'required' ? error?.message : ''}</div>
                      </div>
                      <PriceField
                        {...field}
                        fullWidth
                        variant='outlined'
                        size='medium'
                        error={invalid}
                        InputProps={{
                          endAdornment: (
                            <div className='flex gap-1 pr-5 md:text-xl text-dark-90 font-bold items-center'>
                              <img src={require('assets/icons/OKG-token.png').default} className='h-5' /> OKG
                            </div>
                          ),
                        }}
                      />
                    </>
                  )}
                />
                <div className='text-tiny md:text-sm text-color-primary'>
                  <div className='font-bold'>Service fee: 4.88%</div>
                  <div>Listing is totally FREE! This service fee only applies when the sale is successful.</div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-48 md:w-96'>
            {isLoading ? (
              <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'}>
                <CircularProgress color='inherit' className='w-5 h-5 mr-1.5' /> PROCESSING
              </DesignButton>
            ) : (
              <DesignButton
                fullWidth
                design='yellow'
                size={isMobile ? 'medium' : 'large'}
                onClick={() => {
                  handleSubmit((values) => sellCocoon(values.price))();
                }}
              >
                START LISTING
              </DesignButton>
            )}
          </div>
        </>
      )}
      {!isLoading && <CloseButton onClick={onClose} />}
    </div>
  );
};

export default PopupSellCocoon;
