import { Link } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { PopupController } from 'models/Common';
import { useNavigate } from 'react-router-dom';
import { profileRoute } from 'routes';

const PopupGuideLine = ({ onSuccess, onClose }: PopupController) => {
  const navigate = useNavigate();
  const handleToInventory = () => {
    navigate({ pathname: profileRoute.inventory.url, search: 'tab=heroes' });
    onClose();
  };

  return (
    <>
      <div className='md:p-10' style={{ maxWidth: 1200 }}>
        <div className='flex flex-col gap-5 md:gap-16 bg-color-dark p-2 md:pt-8 px-7 md:px-44 rounded-lg'>
          <div className='text-center text-color-golden md:text-3xl font-semibold'>HOW TO EXPLORE FORBIDDEN LAND</div>
          <div className='text-xs md:text-xl font-medium'>
            <div className='flex flex-col-reverse md:flex-row items-start md:items-center gap-3 md:gap-16 mb-2 md:mb-8'>
              <div className='flex flex-1 justify-between'>
                {[...Array(3)].map((_, index: number) => (
                  <img key={index} src={require('../../../assets/images/hero-card.png').default} className='h-28 md:h-auto' />
                ))}
              </div>
              <div className='flex-1'>
                <span className='text-color-golden'>Step 1:</span> Click at any hero in your Inventory
              </div>
            </div>
            <div className='flex flex-col-reverse md:flex-row items-start md:items-center md:gap-16'>
              <div className='flex-1'>
                <img src={require('../../../assets/images/image-button-explore.png').default} className='w-full' />
              </div>
              <div className='flex-1'>
                <span className='text-color-golden'>Step 2:</span> In the Hero Details screen, click “Start Exploring”
                Button
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-col md:flex-row gap-1 md:gap-6 items-center md:justify-center mb-2 md:mb-6'>
              <DesignButton design='green' className='md:h-15 w-52 md:w-80 md:text-xl' onClick={handleToInventory}>
                TO INVENTORY
              </DesignButton>
              <Link href='https://forbidden-land.ookeenga.io/free' target='_blank'>
                <DesignButton design='green' className='md:h-15 w-52 md:w-80 md:text-xl' onClick={onClose}>
                  LOG IN AS FREE PLAYER
                </DesignButton>
              </Link>
            </div>
            <div className='text-color-primary text-tiny md:text-base mb-3 text-center'>
              Tips: You can quickly switch heroes in Forbidden Land using the ID of your Heroes
            </div>
          </div>
        </div>
      </div>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default PopupGuideLine;
