export const isValidAddress = (text: string) => {
  return /^0x[0-9a-fA-F]{40}$/.test(text);
};

export const isValidTransID = (text: string) => {
  return /^0x[0-9a-fA-F]{64}$/.test(text);
};

export const isValidURL = (text: string) => {
  try {
    new URL(text);
    return true;
  } catch {
    return false;
  }
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}/;
