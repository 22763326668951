import { CircularProgress, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton, StyledCheckBox } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { authService, walletService } from 'services';
import { web3 } from 'contracts';
import { useNavigate } from 'react-router-dom';
import { profileRoute } from 'routes';
import { closePopupRegister } from 'reducers/register';
import { store } from 'reducers';
import { useWindowDimensions } from 'hooks';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/profile';
import { passwordRegex } from 'utils/validator';
import { useState } from 'react';
import { termOfUse } from './TermsOfUse';

const PriceField = styled(TextField)`
  box-shadow: 0px -5px #705439;
  border-radius: 10px;
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: #463024 !important;
    padding: 11px;
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
  & .Mui-disabled {
    color: #463024 !important;
    -webkit-text-fill-color: #463024 !important;
  }
`;

const InputTitle = ({ title }: { title: string }) => <div className='text-xs md:text-base mb-2'>{title}</div>;

const PopupRegister = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  // const { pathname } = useLocation();
  // const pathnames = pathname.split('/');
  // const refCodeRaw = pathnames[1] === 'refer' ? pathnames[2] : '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, watch, handleSubmit } = useForm({ mode: 'onChange' });
  const { username, email, password, refCode } = watch();
  const [isDisplayTerm, setIsDisplayTerm] = useState(false);
  const [checkBox1, setCheckBox1] = useState(false);

  const { mutate: register, isLoading } = useMutation(
    async () => {
      let address;
      try {
        [address] = await web3.eth.requestAccounts();
      } catch {
        [address] = await web3.eth.getAccounts();
      }
      address = web3.utils.toChecksumAddress(address);
      const { nonce } = await authService.getNonce({ address });
      const message = `Welcome to OKG Marketplace!\n\nClick "Sign" to sign in. No password needed!\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;
      const signature = await web3.eth.personal.sign(message, address, '');
      await authService.register({ username, email, password, signedStr: signature, addr: address, refCode });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Register successfully!', { variant: 'success' });
        setIsDisplayTerm(false);
        dispatch(signOut());
        walletService.connectWallet();
        navigate(profileRoute.accountOverview.url);
        store.dispatch(closePopupRegister());
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Register failed!', { variant: 'error' });
        setIsDisplayTerm(false);
      },
    },
  );

  return (
    <div
      className='flex flex-col items-center text-color-secondary md:px-16 py-5 md:py-10'
      style={{ width: isMobile ? 'auto' : 631 }}
    >
      {!isDisplayTerm ? (
        <>
          <div className='text-white font-skadi text-xl md:text-3xl text-center'>YOU ARE ALMOST THERE!</div>
          <div className='text-sm md:text-xl text-center mb-2.5' style={{ maxWidth: 480 }}>
            Create and link your game account to fully explore the marketplace
          </div>
          <div className='w-full'>
            <Controller
              name='username'
              defaultValue=''
              control={control}
              rules={{
                required: true,
                minLength: 8,
                maxLength: 100,
              }}
              render={({ field, fieldState: { invalid, error } }) => {
                let mes = 'Username must have minimum eight characters and should not contain spaces.';
                if (error?.type === 'maxLength') {
                  mes = `Wrong format. Try again.`;
                }

                return (
                  <div className='mb-2 md:mb-5'>
                    <InputTitle title='My username' />
                    <PriceField
                      {...field}
                      fullWidth
                      variant='outlined'
                      placeholder='username'
                      size='medium'
                      error={invalid}
                    />
                    {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>{mes}</div>}
                  </div>
                );
              }}
            />
            <Controller
              name='email'
              defaultValue=''
              control={control}
              rules={{
                required: true,
                pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <div className='mb-2 md:mb-5'>
                  <InputTitle title='My email' />
                  <PriceField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='ookeenga@abc.com'
                    size='medium'
                    error={invalid}
                  />
                  {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>Invalid email. Try again</div>}
                </div>
              )}
            />
            {/* <Controller
              name='refCode'
              defaultValue={refCodeRaw ?? ''}
              control={control}
              rules={{
                minLength: 8,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <div className='mb-2 md:mb-5'>
                  <InputTitle title='Referral code' />
                  <PriceField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='refCode'
                    size='medium'
                    disabled={!!refCodeRaw}
                    error={invalid}
                  />
                  {invalid && (
                    <div className='text-red-500 text-tiny md:text-sm mt-1'>Must be longer than 7 characters</div>
                  )}
                </div>
              )}
            /> */}
            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{
                required: true,
                minLength: 8,
                pattern: passwordRegex,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <div className='mb-2 md:mb-5'>
                  <InputTitle title='My password' />
                  <PriceField
                    {...field}
                    fullWidth
                    type='password'
                    variant='outlined'
                    placeholder='*********'
                    size='medium'
                    error={invalid}
                  />
                  {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>Wrong format. Try again.</div>}
                  <div className='text-color-primary text-tiny md:text-sm mt-1'>
                    Password must have minimum eight characters, at least one uppercase letter, one lowercase letter,
                    one number and one special character
                  </div>
                </div>
              )}
            />
            <Controller
              name='rePassword'
              defaultValue=''
              control={control}
              rules={{
                required: true,
                validate: (value) => value === password,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <div className='mb-5 md:mb-10'>
                  <InputTitle title='Retype password' />
                  <PriceField
                    {...field}
                    fullWidth
                    type='password'
                    variant='outlined'
                    placeholder='*********'
                    size='medium'
                    error={invalid}
                  />
                  {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>Password does not match</div>}
                </div>
              )}
            />
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            className='md:w-96'
            onClick={() => {
              handleSubmit(() => setIsDisplayTerm(true))();
            }}
          >
            CREATE ACCOUNT
          </DesignButton>
        </>
      ) : (
        <>
          <div className='text-xl md:text-3xl font-skadi mb-4 text-center'>TERM OF USE & PRIVATE POLICY</div>
          <div
            className='text-xs md:text-base p-6 bg-color-secondary text-black overflow-auto mb-4'
            style={{ borderRadius: 10 }}
          >
            <div className='overflow-auto' style={{ maxHeight: 316 }}>
              {termOfUse.map((term, index) => (
                <div key={index}>
                  <div className='font-bold'>{term.title}</div>
                  {term.text}
                </div>
              ))}
            </div>
          </div>
          <div className='text-xs md:text-base flex items-end gap-2 mb-4 md:mb-6'>
            <StyledCheckBox
              checked={checkBox1}
              handleChange={(checked: boolean) => {
                setCheckBox1(checked);
              }}
            />
            I have read and agree to all
          </div>
          {isLoading ? (
            <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'} className='md:w-96'>
              <CircularProgress color='inherit' className='w-5 h-5 mr-1.5' /> PROCESSING
            </DesignButton>
          ) : checkBox1 ? (
            <DesignButton
              fullWidth
              design='yellow'
              size={isMobile ? 'medium' : 'large'}
              className='md:w-96'
              onClick={() => {
                register();
              }}
            >
              CONTINUE
            </DesignButton>
          ) : (
            <DesignButton fullWidth design='gray' size={isMobile ? 'medium' : 'large'} className='md:w-96'>
              CONTINUE
            </DesignButton>
          )}
        </>
      )}
      <CloseButton
        onClick={() => {
          if (isDisplayTerm) {
            setIsDisplayTerm(false);
          } else {
            store.dispatch(closePopupRegister());
          }
        }}
      />
    </div>
  );
};

export default PopupRegister;
