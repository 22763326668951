import BigNumber from 'bignumber.js';
import { erc20Contract, web3 } from 'contracts';
import { withdrawalService } from 'services';
import { TokenEnum } from 'services/helper';

export const depositToken = async (
  address: string | undefined,
  amount: number,
  tokenAddress: string,
  token: TokenEnum,
  swapContractAddress: string,
  swapContract: any,
) => {
  let depositID = '';
  try {
    const res = await withdrawalService.deposit({
      token,
      amount,
    });
    depositID = res?._id;
    const decimalPoint = new BigNumber(10).pow(18);
    const bnAmount = new BigNumber(amount).multipliedBy(decimalPoint);
    const allowance = await erc20Contract(tokenAddress).methods.allowance(address, swapContractAddress).call();
    if (Number(allowance) < amount) {
      await erc20Contract(tokenAddress!)
        .methods.approve(swapContractAddress, web3.utils.toTwosComplement(-1))
        .send({ from: address });
    }
    const resBurn = await swapContract(swapContractAddress!)
      .methods.deposit(bnAmount.toFixed(), res._id, res._signature)
      .send({
        from: address,
      });
    await withdrawalService.confirmWithdrawalDeposit(depositID ?? '', token, resBurn?.transactionHash ?? '');
  } catch (e) {
    await withdrawalService.deleteDeposit(depositID, token);
    throw e;
  }
};
