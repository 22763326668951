import { Close, Menu as MenuIcon, AccountBalanceWallet, ArrowDropDown, ExpandMore } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Container,
  IconButton,
  ListItemButton,
  ListItemButtonProps,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  Toolbar,
  Link as MuiLink,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Dialog,
} from '@mui/material';
import { CloseButton, NetworkBar } from 'components';
import { erc20Contract } from 'contracts';
import { GAME_URL, KAB_TOKEN_ADDRESS, OKG_TOKEN_ADDRESS } from 'env';
import { useAnchor, useWindowDimensions } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { store } from 'reducers';
import { profileSelector, signOut, updateInfo } from 'reducers/profile';
import { profileRoute, publicRoute, scholarshipRoute } from 'routes';
import { walletService } from 'services';
import { customizeNumber, shorten } from 'utils/common';

const CustomListItemButton = ({ url, ...props }: { url?: string } & ListItemButtonProps) => {
  const { isMobile } = useWindowDimensions();
  const location = useLocation();
  const isAt = url?.includes(location.pathname);

  return (
    <ListItemButton
      className={`font-black px-2 rounded text-color-${isAt ? 'legendary' : isMobile ? 'secondary' : 'primary'}`}
      {...props}
    />
  );
};

const HeaderItem = ({
  url,
  state,
  href,
  ...props
}: { url?: string; state?: string; href?: string } & ListItemButtonProps) => {
  return url ? (
    <Link to={url} state={state}>
      <CustomListItemButton url={url} {...props} />
    </Link>
  ) : href ? (
    <MuiLink href={href} target='_blank' underline='none'>
      <CustomListItemButton url={url} {...props} />
    </MuiLink>
  ) : (
    <CustomListItemButton url={url} {...props} />
  );
};

const ButtonPlayNow = styled(Button)`
  background: url(${require('assets/images/button-play-now.png').default}) no-repeat center center;
  background-size: 100% 100%;
  height: 48px;
  width: 112px;
  padding: 0px;
  flex: none;
`;

const CustomMenuItem = styled(MenuItem)`
  font-weight: 900;
  padding: 8px 16px;
`;

const TokenGroup = ({
  image,
  icon,
  handleClick,
  value,
  background,
  border,
}: {
  image: string;
  icon: string;
  handleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  value: string;
  background: string;
  border: string;
}) => (
  <div
    className='relative flex justify-between items-center w-full md:w-auto'
    style={{
      background,
      border: `1px solid ${border}`,
      borderRadius: 10,
      padding: '2px 32px 2px 2px',
      minWidth: 148,
      width: 'fit-content',
    }}
  >
    <img
      src={require(`assets/icons/${image}.png`).default}
      className='w-auto h-8 absolute -right-2 top-1/2'
      style={{ transform: 'translateY(-50%)' }}
    />
    <IconButton className='p-1.5' onClick={handleClick}>
      <img src={require(`assets/icons/${icon}.png`).default} className='w-5 h-5' />
    </IconButton>
    <div className='font-bold text-base font-skadi'>{value}</div>
  </div>
);

const resourcesUrl = [
  {
    title: 'Ookeenga Wiki',
    url: 'https://ookeenga.fandom.com/wiki/Ookeenga_Wiki',
  },
  {
    title: `Deck's Tactic`,
    url: 'https://www.youtube.com/watch?v=W-92Il6rTfE&list=PLxWky5BSAGEUV1khIZcG7mSaXBuo0slJX',
  },
];

const getOKGUrl = [
  {
    title: 'PancakeSwap',
    url: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
    button: require('assets/icons/icon-pancakeswap-button.png').default,
    textImage: require('assets/images/pancakeswap-text.png').default,
  },
  {
    title: 'BYBIT',
    url: 'https://www.bybit.com/en-US/trade/spot/OKG/USDT',
    button: require('assets/icons/icon-bybit-button.png').default,
    textImage: require('assets/images/bybit-text.png').default,
  },
];

const breakPoint = [1400, 1024, 575];

const dropDownSetting = {
  PaperProps: {
    sx: {
      overflow: 'visible',
      background: '#342718',
      border: '1px solid #6B5439',
      marginTop: 1,
      '&:before': {
        display: 'none',
      },
    },
  },
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAtProfile = location.pathname.includes('profile');
  const { isLoggedIn, address } = useSelector(profileSelector);
  const {
    windowDimensions: { width },
  } = useWindowDimensions();
  const [anchorEl, open, onOpen, onClose] = useAnchor();
  const [anchorElGetOKG, openGetOKG, onOpenGetOKG, onCloseGetOKG] = useAnchor();
  const [anchorElResources, openResources, onOpenResources, onCloseResources] = useAnchor();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupGetOKG, setOpenPopupGetOKG] = useState(false);

  const { data: tokens } = useQuery(
    ['fetchTokenBalance', { address }],
    async ({ queryKey }) => {
      const { address } = queryKey[1] as any;
      const OKG = await erc20Contract(OKG_TOKEN_ADDRESS!).methods.balanceOf(address).call();

      const KAB = await erc20Contract(KAB_TOKEN_ADDRESS!).methods.balanceOf(address).call();
      const newInfos = { OKG, KAB };

      return newInfos;
    },
    {
      enabled: !!address,
    },
  );

  const getTokenUrl = useMemo(
    () => [
      {
        title: 'Get OKG',
        image: 'OKG-token',
        url: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
        icon: 'icon-get-OKG',
        background: '#290E0B',
        border: '#503509',
        value: customizeNumber(Math.round(Number(tokens?.OKG ?? '0') / 1e18)),
      },
      {
        title: 'Get KAB',
        image: 'KAB-token',
        url: 'https://pancakeswap.finance/swap?outputCurrency=0x2c83d3786fa3c068592aa2dcac650f10d4bbba1d&chainId=56&inputCurrency=0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
        icon: 'icon-get-KAB',
        background: '#0B1B02',
        border: '#194006',
        value: customizeNumber(Math.round(Number(tokens?.KAB ?? '0') / 1e18)),
      },
    ],
    [tokens?.KAB, tokens?.OKG],
  );

  const handleClosePopop = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    store.dispatch(updateInfo({ tokens: { OKG: tokens?.OKG ?? '0', KAB: tokens?.KAB ?? '0' } }));
  }, [tokens?.KAB, tokens?.OKG]);

  return (
    <AppBar style={{ background: '#3C2C19CC', borderBottom: '1px solid #6C6C6C', backdropFilter: 'blur(8px)' }}>
      <Toolbar component={Container}>
        <NetworkBar />
        <MuiLink href='https://ookeenga.io/' underline='none' className='flex-none'>
          <img src={require('assets/images/logo-header.png').default} className='h-8 md:h-12 mb-1' />
        </MuiLink>

        {width > breakPoint[0] && (
          <>
            <MenuList className='flex flex-row gap-3 ml-6'>
              <HeaderItem url={publicRoute.marketplace.path}>Marketplace</HeaderItem>
              <HeaderItem url={publicRoute.breeding.path}>Breeding</HeaderItem>
              <HeaderItem url={scholarshipRoute.management.url()}>Scholarship</HeaderItem>
              <HeaderItem onClick={onOpenResources}>
                Resources <ArrowDropDown />
              </HeaderItem>
              <HeaderItem url={publicRoute.dailyBonus.path}>
                <div>
                  <span>Daily Bonus</span>
                  <span
                    className='absolute text-white -top-1 -right-2 rounded-lg px-1.5'
                    style={{
                      background: '-webkit-linear-gradient(250.28deg, #FB9600 13.9%, #FFB84F 89.37%)',
                      fontSize: '11px',
                      boxShadow: ' 0px 4px 4px 0px rgba(251, 150, 0.2, 0.5)',
                    }}
                  >
                    New
                  </span>
                </div>
              </HeaderItem>
            </MenuList>
            <Menu
              anchorEl={anchorElResources}
              {...dropDownSetting}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              open={openResources}
              onClose={onCloseResources}
              onClick={onCloseResources}
            >
              {resourcesUrl.map((info, index) => (
                <CustomMenuItem
                  key={index}
                  onClick={() => {
                    window.open(info.url, '_blank');
                  }}
                >
                  {info.title}
                </CustomMenuItem>
              ))}
            </Menu>
          </>
        )}
        <Menu
          anchorEl={anchorElGetOKG}
          {...dropDownSetting}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={openGetOKG}
          onClose={onCloseGetOKG}
          onClick={onCloseGetOKG}
        >
          {getOKGUrl.map((info, index) => (
            <CustomMenuItem
              key={index}
              onClick={() => {
                window.open(info.url, '_blank');
              }}
            >
              <img src={info.textImage} />
            </CustomMenuItem>
          ))}
        </Menu>

        <div className='flex-1' />

        {width > breakPoint[1] && (
          <div className='flex gap-6 mr-8'>
            <TokenGroup
              image={getTokenUrl[0].image}
              handleClick={(e) => onOpenGetOKG(e)}
              value={getTokenUrl[0].value}
              background={getTokenUrl[0].background}
              border={getTokenUrl[0].border}
              icon={getTokenUrl[0].icon}
            />
            <TokenGroup
              image={getTokenUrl[1].image}
              handleClick={() => window.open(getTokenUrl[1].url, '_blank')}
              value={getTokenUrl[1].value}
              background={getTokenUrl[1].background}
              border={getTokenUrl[1].border}
              icon={getTokenUrl[1].icon}
            />
          </div>
        )}
        <div className='flex items-center gap-2'>
          {width >= breakPoint[2] && (
            <>
              <ButtonPlayNow onClick={() => window.open(GAME_URL ?? '', '_blank')} />
              {isLoggedIn ? (
                <>
                  <Button
                    variant='outlined'
                    className='flex gap-2.5 bg-color-greyish text-color-secondary font-black h-10'
                    style={{ border: '0.7px solid #B7A284', borderRadius: 8 }}
                    onClick={onOpen}
                  >
                    <AccountBalanceWallet />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    {...dropDownSetting}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={open}
                    onClose={onClose}
                    onClick={onClose}
                  >
                    <div className='px-4 mb-4'>
                      <div
                        className='flex gap-2.5 bg-color-greyish text-color-secondary font-black px-3 py-2 mb-4 w-full'
                        style={{ border: '0.7px solid #B7A284', borderRadius: 8, minWidth: 231 }}
                      >
                        <AccountBalanceWallet />
                        {shorten(address!, 5, 4)}
                      </div>
                      {isLoggedIn && width < breakPoint[1] && width >= breakPoint[2] && (
                        <div className='flex gap-6 mb-4'>
                          <TokenGroup
                            image={getTokenUrl[0].image}
                            handleClick={() => setOpenPopupGetOKG(true)}
                            value={getTokenUrl[0].value}
                            background={getTokenUrl[0].background}
                            border={getTokenUrl[0].border}
                            icon={getTokenUrl[0].icon}
                          />
                          <TokenGroup
                            image={getTokenUrl[1].image}
                            handleClick={() => window.open(getTokenUrl[1].url, '_blank')}
                            value={getTokenUrl[1].value}
                            background={getTokenUrl[1].background}
                            border={getTokenUrl[1].border}
                            icon={getTokenUrl[1].icon}
                          />
                        </div>
                      )}
                      <Divider style={{ borderWidth: 1, borderColor: '#5F4A2F' }} />
                    </div>
                    <CustomMenuItem onClick={() => navigate(profileRoute.accountOverview.url)}>
                      Account Overview
                    </CustomMenuItem>
                    <CustomMenuItem onClick={() => navigate(publicRoute.profile.path)}>Inventory</CustomMenuItem>
                    <CustomMenuItem onClick={() => navigate(profileRoute.activity.url)}>Activity</CustomMenuItem>
                    <CustomMenuItem onClick={() => dispatch(signOut())}>Disconnect</CustomMenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Button
                    variant='outlined'
                    className='bg-color-greyish text-color-secondary font-black w-40 h-12'
                    style={{ border: '0.7px solid #B7A284', borderRadius: 8 }}
                    onClick={() => walletService.connectWallet()}
                  >
                    Connect Wallet
                  </Button>
                </>
              )}
            </>
          )}
          {isLoggedIn && width < breakPoint[1] && (
            <Dialog
              maxWidth='md'
              open={openPopupGetOKG}
              PaperProps={{ className: 'm-0 w-full', style: { maxWidth: 624 } }}
            >
              <div className='py-8 flex flex-col gap-4 items-center'>
                <div className='text-3xl font-black'>SELECT AN EXCHANGE</div>
                {getOKGUrl.map((info, index) => (
                  <div key={index}>
                    <img src={info.button} className='cursor-pointer' onClick={() => window.open(info.url, '_blank')} />
                  </div>
                ))}

                <CloseButton onClick={() => setOpenPopupGetOKG(false)} />
              </div>
            </Dialog>
          )}
          {width < breakPoint[0] && (
            <>
              <IconButton onClick={() => setOpenPopup(true)}>
                <MenuIcon />
              </IconButton>

              <Modal open={openPopup} onClose={handleClosePopop}>
                <div className='flex justify-end h-full'>
                  <div className='w-full' style={{ background: '#170A02E5', maxWidth: 575 }}>
                    <div
                      className='sticky top-0 flex justify-between py-4 px-6 z-50'
                      style={{ background: '#28150B80', backdropFilter: 'blur(8px)' }}
                    >
                      <img src={require('assets/images/logo-header.png').default} className='h-10' />
                      <IconButton onClick={handleClosePopop}>
                        <Close />
                      </IconButton>
                    </div>
                    <div className='px-7 overflow-auto h-full pt-5 pb-80'>
                      <MenuList className='flex flex-col gap-7 text-2xl'>
                        <HeaderItem url={publicRoute.marketplace.path} onClick={handleClosePopop}>
                          Marketplace
                        </HeaderItem>
                        <HeaderItem url={publicRoute.breeding.path} onClick={handleClosePopop}>
                          Breeding
                        </HeaderItem>
                        <HeaderItem url={scholarshipRoute.management.url()} onClick={handleClosePopop}>
                          Scholarship
                        </HeaderItem>
                        <HeaderItem url={publicRoute.dailyBonus.path}>
                          <div>
                            <span>Daily Bonus</span>
                            <span
                              className='absolute text-white -top-1 -right-2 rounded-lg px-1.5'
                              style={{
                                background: '-webkit-linear-gradient(250.28deg, #FB9600 13.9%, #FFB84F 89.37%)',
                                fontSize: '11px',
                                boxShadow: ' 0px 4px 4px 0px rgba(251, 150, 0.2, 0.5)',
                              }}
                            >
                              New
                            </span>
                          </div>
                        </HeaderItem>
                        <Accordion className='text-2xl font-black bg-none bg-transparent shadow-none m-0'>
                          <AccordionSummary
                            className={`bg-none bg-transparent px-2 w-fit text-color-${
                              isAtProfile ? 'legendary' : 'secondary'
                            }`}
                            expandIcon={<ExpandMore className='ml-2' />}
                          >
                            Profile
                          </AccordionSummary>
                          <AccordionDetails className='bg-none bg-transparent'>
                            <HeaderItem
                              url={profileRoute.accountOverview.url}
                              onClick={handleClosePopop}
                              sx={{ fontSize: '20px !important' }}
                            >
                              Account Overview
                            </HeaderItem>
                            <HeaderItem
                              url={profileRoute.inventory.url}
                              onClick={handleClosePopop}
                              sx={{ fontSize: '20px !important' }}
                            >
                              Inventory
                            </HeaderItem>
                            <HeaderItem
                              url={profileRoute.activity.url}
                              onClick={handleClosePopop}
                              sx={{ fontSize: '20px !important' }}
                            >
                              Activities
                            </HeaderItem>
                          </AccordionDetails>
                        </Accordion>
                      </MenuList>
                    </div>
                    {width < breakPoint[2] && (
                      <div
                        className='sticky bottom-0 text-color-secondary text-lg font-extrabold z-50'
                        style={{ background: '#28150BE5', backdropFilter: 'blur(8px)' }}
                      >
                        <div
                          className='px-6 py-5'
                          style={{ borderTop: '1px solid #463024', borderBottom: '1px solid #463024' }}
                        >
                          {isLoggedIn ? (
                            <div className='flex flex-col gap-4'>
                              <div
                                className='p-2 pl-6 flex justify-between'
                                style={{ border: '1px solid #463024', borderRadius: 16 }}
                              >
                                <div className='flex gap-1 items-center'>
                                  <AccountBalanceWallet />
                                  {shorten(address!, 5, 4)}
                                </div>
                                <IconButton
                                  className='bg-color-brown'
                                  style={{ borderRadius: 16 }}
                                  onClick={() => dispatch(signOut())}
                                >
                                  <img src={require('assets/icons/icon-logout.png').default} className='h-6' />
                                </IconButton>
                              </div>
                              <div className='grid grid-cols-2 gap-4'>
                                <TokenGroup
                                  image={getTokenUrl[0].image}
                                  handleClick={() => {
                                    setOpenPopupGetOKG(true);
                                    handleClosePopop();
                                  }}
                                  value={getTokenUrl[0].value}
                                  background={getTokenUrl[0].background}
                                  border={getTokenUrl[0].border}
                                  icon={getTokenUrl[0].icon}
                                />
                                <TokenGroup
                                  image={getTokenUrl[1].image}
                                  handleClick={() => window.open(getTokenUrl[1].url, '_blank')}
                                  value={getTokenUrl[1].value}
                                  background={getTokenUrl[1].background}
                                  border={getTokenUrl[1].border}
                                  icon={getTokenUrl[1].icon}
                                />
                              </div>
                            </div>
                          ) : (
                            <Button
                              variant='outlined'
                              className='bg-color-greyish text-lg text-color-secondary font-extrabold w-full h-14'
                              style={{ border: '1px solid #B7A284', borderRadius: 16 }}
                              onClick={() => walletService.connectWallet()}
                            >
                              CONNECT WALLET
                            </Button>
                          )}
                        </div>
                        <div className='flex justify-center py-2'>
                          <ButtonPlayNow onClick={() => window.open(GAME_URL ?? '', '_blank')} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>

        {/* {windowDimensions.width <= 1364 ? (
          <>
            <div className='flex-1' />
            <IconButton onClick={() => setOpenPopup(true)}>
              <MenuIcon />
            </IconButton>

            <Modal open={openPopup} onClose={handleClosePopop} style={{ background: '#170A02E5' }}>
              <div className='h-full'>
                <div
                  className='sticky top-0 flex justify-between py-4 px-6 z-50'
                  style={{ background: '#28150B80', backdropFilter: 'blur(8px)' }}
                >
                  <img src={require('assets/images/logo-header.png').default} className='h-10' />
                  <IconButton onClick={handleClosePopop}>
                    <Close />
                  </IconButton>
                </div>
                <div className='px-7 overflow-auto h-full pt-5 pb-80'>
                  <MenuList className='flex flex-col gap-7'>
                    <HeaderItem url={publicRoute.marketplace.path} onClick={handleClosePopop}>
                      Marketplace
                    </HeaderItem>
                    <HeaderItem url={publicRoute.breeding.path} onClick={handleClosePopop}>
                      Breeding
                    </HeaderItem>
                    <HeaderItem url={scholarshipRoute.management.url()} onClick={handleClosePopop}>
                      Scholarship
                    </HeaderItem>
                    <Accordion className='text-2xl font-black bg-none bg-transparent shadow-none m-0'>
                      <AccordionSummary
                        className={`bg-none bg-transparent w-fit text-color-${isAtProfile ? 'legendary' : 'secondary'}`}
                        expandIcon={<ExpandMore className='ml-2' />}
                      >
                        Profile
                      </AccordionSummary>
                      <AccordionDetails className='bg-none bg-transparent'>
                        <HeaderItem
                          url={profileRoute.accountOverview.url}
                          onClick={handleClosePopop}
                          sx={{ fontSize: '20px !important' }}
                        >
                          Account Overview
                        </HeaderItem>
                        <HeaderItem
                          url={profileRoute.inventory.url}
                          onClick={handleClosePopop}
                          sx={{ fontSize: '20px !important' }}
                        >
                          Inventory
                        </HeaderItem>
                        <HeaderItem
                          url={profileRoute.activity.url}
                          onClick={handleClosePopop}
                          sx={{ fontSize: '20px !important' }}
                        >
                          Activities
                        </HeaderItem>
                      </AccordionDetails>
                    </Accordion>
                  </MenuList>
                </div>
                <div
                  className='sticky bottom-0 text-color-secondary text-lg font-extrabold z-50'
                  style={{ background: '#28150BE5', backdropFilter: 'blur(8px)' }}
                >
                  <div
                    className='px-6 py-5'
                    style={{ borderTop: '1px solid #463024', borderBottom: '1px solid #463024' }}
                  >
                    {isLoggedIn ? (
                      <div className='flex flex-col gap-4'>
                        <div
                          className='p-2 pl-6 flex justify-between'
                          style={{ border: '1px solid #463024', borderRadius: 16 }}
                        >
                          <div className='flex gap-1 items-center'>
                            <AccountBalanceWallet />
                            {shorten(address!, 5, 4)}
                          </div>
                          <IconButton
                            className='bg-color-brown'
                            style={{ borderRadius: 16 }}
                            onClick={() => dispatch(signOut())}
                          >
                            <img src={require('assets/icons/icon-logout.png').default} className='h-6' />
                          </IconButton>
                        </div>
                        {getTokenUrl.map((info, index) => (
                          <TokenGroup
                            key={index}
                            image={info.image}
                            url={info.url}
                            value={info.value}
                            background={info.background}
                            border={info.border}
                            icon={info.icon}
                          />
                        ))}
                      </div>
                    ) : (
                      <Button
                        variant='outlined'
                        className='bg-color-greyish text-lg text-color-secondary font-extrabold w-full h-14'
                        style={{ border: '1px solid #B7A284', borderRadius: 16 }}
                        onClick={() => walletService.connectWallet()}
                      >
                        CONNECT WALLET
                      </Button>
                    )}
                  </div>
                  <div className='flex justify-center py-2'>
                    <ButtonPlayNow onClick={() => window.open(GAME_URL ?? '', '_blank')} />
                  </div>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <>
            <MenuList className='flex flex-row gap-3 ml-6'>
              <HeaderItem url={publicRoute.marketplace.path}>Marketplace</HeaderItem>
              <HeaderItem url={profileRoute.inventory.url}>Inventory</HeaderItem>
              <HeaderItem url={publicRoute.breeding.path}>Breeding</HeaderItem>
              <HeaderItem url={scholarshipRoute.management.url()}>Scholarship</HeaderItem>
              <HeaderItem onClick={onOpenResources}>
                Resources <ArrowDropDown />
              </HeaderItem>
              <HeaderItem url={publicRoute.dailyBonus.url()}>Daily Bonus</HeaderItem>
            </MenuList>
            <Menu
              anchorEl={anchorElResources}
              PaperProps={{
                sx: {
                  overflow: 'visible',
                  backgroundColor: '#423429',
                  border: '1px solid #B7A284',
                  marginTop: 0,
                  width: 153,
                  '&:before': {
                    display: 'none',
                  },
                },
              }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              open={openResources}
              onClose={onCloseResources}
              onClick={onCloseResources}
            >
              {resourcesUrl.map((info, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    window.open(info.url, '_blank');
                  }}
                >
                  <div className='font-black py-1'>{info.title}</div>
                </MenuItem>
              ))}
            </Menu>

            <div className='flex-1' />

            {isLoggedIn && (
              <div className='flex gap-6 mr-8'>
                {getTokenUrl.map((info, index) => (
                  <TokenGroup
                    key={index}
                    image={info.image}
                    url={info.url}
                    value={info.value}
                    background={info.background}
                    border={info.border}
                    icon={info.icon}
                  />
                ))}
              </div>
            )}
            <div className='flex items-center gap-2'>
              <ButtonPlayNow onClick={() => window.open(GAME_URL ?? '', '_blank')} />
              {isLoggedIn ? (
                <>
                  <Button
                    variant='outlined'
                    className='flex gap-2.5 bg-color-greyish text-color-secondary font-black w-40 h-12'
                    style={{ border: '0.7px solid #B7A284', borderRadius: 8 }}
                    onClick={onOpen}
                  >
                    <AccountBalanceWallet />
                    {shorten(address!, 5, 4)}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    PaperProps={{
                      sx: {
                        overflow: 'visible',
                        backgroundColor: '#463024',
                        border: '1px solid #966740',
                        marginTop: 1.5,
                        width: 256,
                        '&:before': {
                          ...{ content: '""', display: 'block', zIndex: 0 },
                          ...{ position: 'absolute', top: 0, right: 14, width: 10, height: 10 },
                          ...{ borderWidth: 1, borderColor: '#966740', borderBottom: 0, borderRight: 0 },
                          backgroundColor: '#5c493e',
                          transform: 'translateY(-50%) rotate(45deg)',
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={open}
                    onClose={onClose}
                    onClick={onClose}
                  >
                    <MenuItem onClick={() => navigate(profileRoute.accountOverview.url)}>Account Overview</MenuItem>
                    <MenuItem onClick={() => navigate(publicRoute.profile.path)}>Inventory</MenuItem>
                    <MenuItem onClick={() => navigate(profileRoute.activity.url)}>Activity</MenuItem>
                    <MenuItem onClick={() => dispatch(signOut())}>Disconnect</MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Button
                    variant='outlined'
                    className='bg-color-greyish text-color-secondary font-black w-40 h-12'
                    style={{ border: '0.7px solid #B7A284', borderRadius: 8 }}
                    onClick={() => walletService.connectWallet()}
                  >
                    Connect Wallet
                  </Button>
                </>
              )}
            </div>
          </>
        )} */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
