import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';

export type RegisterState = {
  isOpenPopupRegister: boolean;
};

export const registerSlice = createSlice({
  name: 'register',
  initialState: { isOpenPopupRegister: false } as RegisterState,
  reducers: {
    openPopupRegister: () => {
      return { isOpenPopupRegister: true };
    },
    closePopupRegister: () => {
      return { isOpenPopupRegister: false };
    },
  },
});

export const { openPopupRegister, closePopupRegister } = registerSlice.actions;

export const registerSelector = ({ register }: RootState) => register;

export default registerSlice.reducer;
