import {
  GetNonceData,
  GetNonceType,
  GetTokenData,
  GetTokenType,
  RegisterType,
  ChangePasswordType,
  ForgotPasswordType,
  ResetPasswordType,
} from 'models/Auth';
import { client } from './axios';

const getNonce = ({ address }: GetNonceType): Promise<GetNonceData> => client.get(`/api/auth/nonce/${address}`);
const getToken = (body: GetTokenType): Promise<GetTokenData> => client.post(`/api/auth/login`, body);
const register = (body: RegisterType) => client.post(`/api/auth/register`, body);
const changePassword = (body: ChangePasswordType) => client.post(`/api/auth/change-password`, body);
const forgotPassword = (body: ForgotPasswordType) => client.post(`/api/auth/forgot-password`, body);
const resetPassword = (body: ResetPasswordType) => client.post(`/api/auth/reset-password`, body);

export default {
  getNonce,
  getToken,
  register,
  changePassword,
  forgotPassword,
  resetPassword,
};
