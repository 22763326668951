import { Button, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { Controller, useForm } from 'react-hook-form';

const AmountField = styled(TextField)`
  border-radius: 8px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 8px;
  }
  & .MuiOutlinedInput-input {
    font-family: 'Avenir';
    font-weight: 800;
    font-size: 24px;
    color: #392609;
    padding: 8px 16px;
    @media (max-width: 800px) {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

const PopupTransfer = ({
  onClose,
  handleClick,
  maxLimit,
  username,
}: {
  onClose: () => void;
  handleClick: (gKab: number) => void;
  maxLimit: number;
  username: string;
}) => {
  const { isMobile } = useWindowDimensions();
  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });

  return (
    <div className='px-2 md:px-8 py-4 md:py-10 text-color-secondary' style={{ maxWidth: 520 }}>
      <div className='font-skadi text-xl md:text-3xl mb-4 md:mb-8 text-center'>
        {`INTERNAL TRANSFER TO SUB-ACCOUNT `}
        <span className='text-color-legendary'>{username}</span>
      </div>
      <Controller
        name='amount'
        defaultValue=''
        control={control}
        rules={{
          required: true,
          pattern: /^\d*\.?\d*$/,
          min: 1,
          max: maxLimit,
        }}
        render={({ field, fieldState: { invalid, error } }) => {
          let mes = 'Please enter a positive number';
          let color = '#EF4444';
          if (error?.type === 'max') {
            mes = `Your maximum amount is ${maxLimit.toLocaleString()} gKAB`;
          }

          return (
            <div className='mb-4 md:mb-8 text-color-primary'>
              <div className='text-sm md:text-base font-black mb-2 md:mb-1'>
                AMOUNT <span style={{ color: '#FF613F' }}>*</span>
              </div>
              <AmountField
                {...field}
                fullWidth
                variant='outlined'
                placeholder='0'
                size='medium'
                error={invalid}
                InputProps={{
                  endAdornment: (
                    <div
                      className='flex gap-2 md:pr-5 md:text-xl font-avenir font-bold items-center'
                      style={{ color: '#392609' }}
                    >
                      <Button
                        variant='contained'
                        className='font-bold text-color-primary text-xs md:text-sm'
                        style={{ background: '#86632D', borderRadius: 8 }}
                        onClick={() => setValue('amount', maxLimit, { shouldValidate: true })}
                      >
                        Max
                      </Button>
                      gKAB
                    </div>
                  ),
                  type: 'number',
                  onKeyDown: (el: any) => {
                    if (
                      el.which === 189 ||
                      el.which === 190 ||
                      el.which === 109 ||
                      el.which === 110 ||
                      el.which === 107 ||
                      el.which === 187 ||
                      el.which === 69 ||
                      el.which === 231
                    )
                      el.preventDefault();
                  },
                }}
              />
              {invalid && (
                <div className='text-tiny md:text-sm mt-1' style={{ color }}>
                  {mes}
                </div>
              )}
            </div>
          );
        }}
      />
      <DesignButton
        fullWidth
        design='yellow'
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          handleSubmit((values: any) => {
            handleClick(values.amount ?? 0);
            onClose();
          })();
        }}
      >
        CONFIRM
      </DesignButton>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupTransfer;
