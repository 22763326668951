import { Activity, AccountOverview, Inventory } from 'views/Profile';

type RouteType = {
  path: string;
  url: string;
  element: JSX.Element;
  auth: boolean;
  name: string;
};

type ProfileRouteType = {
  [key: string]: RouteType;
};

export const profileRoute: ProfileRouteType = {
  accountOverview: {
    path: '/overview',
    url: '/profile/overview',
    element: <AccountOverview />,
    auth: true,
    name: 'Account Overview',
  },
  inventory: {
    path: '/inventory',
    url: '/profile/inventory',
    element: <Inventory />,
    auth: false,
    name: 'Inventory',
  },
  activity: {
    path: '/activity',
    url: '/profile/activity',
    element: <Activity />,
    auth: false,
    name: 'Activity',
  },
  // referral: {
  //   path: '/referral',
  //   url: '/profile/referral',
  //   element: <Referral />,
  //   auth: true,
  //   name: 'Referral',
  // },
};

