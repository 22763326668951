import { Link, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { authService } from 'services';
import { useNavigate } from 'react-router-dom';
import { profileRoute } from 'routes';
import { useOSInfo, useWindowDimensions } from 'hooks';
import { PopupController } from 'models/Common';
import { useState } from 'react';
import { passwordRegex } from 'utils/validator';

const InputField = styled(TextField)`
  box-shadow: 0px -5px #705439;
  border-radius: 10px;
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: #463024;
    padding: 11px;
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
`;

const PopupChangePassword = ({ onSuccess, onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  const [browserName, operatingSystem] = useOSInfo();
  const [isForgetPass, setIsForgetPass] = useState(false);
  const { control, watch, handleSubmit, reset } = useForm({ mode: 'onChange' });
  const { newPassword } = watch();

  const {
    mutate: changePassword,
    isLoading: isLoadingChangePassword,
    isSuccess: isSuccessChangePass,
  } = useMutation(authService.changePassword, {
    onSuccess: () => {
      enqueueSnackbar('Change password successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Change password failed!', { variant: 'error' });
    },
  });

  const {
    mutate: forgotPassword,
    isLoading: isLoadingForgotPassword,
    isSuccess: isSuccessForgotPass,
  } = useMutation(authService.forgotPassword, {
    onSuccess: () => {
      enqueueSnackbar('Email send successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Did not found email!', { variant: 'error' });
    },
  });

  return (
    <div
      className='flex flex-col items-center text-color-secondary md:px-16 py-5 md:py-10'
      style={{ width: isMobile ? 285 : 631 }}
    >
      {isSuccessChangePass || isSuccessForgotPass ? (
        <>
          {isSuccessChangePass && (
            <>
              <img src={require('assets/icons/icon-success.png').default} className='mb-8' />
              <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>PASSWORD CHANGED!</div>
              <div className='text-xs md:text-xl mb-14'>You may login with your new password</div>
              <DesignButton
                fullWidth
                design='yellow'
                size={isMobile ? 'medium' : 'large'}
                className='w-48 md:w-96'
                onClick={() => {
                  navigate(profileRoute.accountOverview.url);
                  onClose();
                }}
              >
                BACK TO PROFILE
              </DesignButton>
            </>
          )}
          {isSuccessForgotPass && (
            <>
              <img src={require('assets/icons/icon-email-success.png').default} className='mb-2' />
              <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>
                AN EMAIL HAS BEEN SENT!
              </div>
              <div className='text-xs md:text-xl text-center mb-14'>
                Please follow the instruction in the email to reset your password
              </div>
              <DesignButton
                fullWidth
                design='yellow'
                size={isMobile ? 'medium' : 'large'}
                className='w-48 md:w-96'
                onClick={() => onClose()}
              >
                CONFIRM
              </DesignButton>
            </>
          )}
        </>
      ) : (
        <>
          <div className='text-white font-skadi text-xl md:text-3xl text-center mb-4'>
            {!isForgetPass ? 'CHANGE PASSWORD' : 'FORGOT PASSWORD?'}
          </div>
          <div className='w-full'>
            {!isForgetPass ? (
              <>
                <Controller
                  name='currentPassword'
                  defaultValue=''
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <div className='mb-2 md:mb-5'>
                      <div className='text-xs md:text-xl mb-2'>Current password</div>
                      <InputField
                        {...field}
                        fullWidth
                        type='password'
                        variant='outlined'
                        placeholder='*********'
                        size='medium'
                        error={invalid}
                      />
                      {invalid && (
                        <div className='text-red-500 text-tiny md:text-sm mt-1'>Please enter current password</div>
                      )}
                    </div>
                  )}
                />
                <div className='flex justify-end mb-8'>
                  <Link
                    className='text-color-secondary cursor-pointer'
                    style={{ textDecorationColor: '#F1E9DC' }}
                    onClick={() => {
                      reset();
                      setIsForgetPass(true);
                    }}
                  >
                    Forgot password?
                  </Link>
                </div>
                <Controller
                  name='newPassword'
                  defaultValue=''
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                    pattern: passwordRegex,
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <div className='mb-2 md:mb-5'>
                      <div className='text-xs md:text-xl mb-2'>New password</div>
                      <InputField
                        {...field}
                        fullWidth
                        type='password'
                        variant='outlined'
                        placeholder='*********'
                        size='medium'
                        error={invalid}
                      />
                      {invalid && (
                        <div className='text-red-500 text-tiny md:text-sm mt-1'>
                          Password must have minimum eight characters, at least one uppercase letter, one lowercase
                          letter, one number and one special character
                        </div>
                      )}
                    </div>
                  )}
                />
                <Controller
                  name='rePassword'
                  defaultValue=''
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value === newPassword,
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <div className='mb-5 md:mb-10'>
                      <div className='text-xs md:text-xl mb-2'>Retype new password</div>
                      <InputField
                        {...field}
                        fullWidth
                        type='password'
                        variant='outlined'
                        placeholder='*********'
                        size='medium'
                        error={invalid}
                      />
                      {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>Password does not match</div>}
                    </div>
                  )}
                />
              </>
            ) : (
              <Controller
                name='email'
                defaultValue=''
                control={control}
                rules={{
                  required: true,
                  pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <div className='mb-2 md:mb-5'>
                    <div className='text-xs md:text-xl text-center mb-2'>
                      Please enter your email to receive instructions
                    </div>
                    <InputField
                      {...field}
                      fullWidth
                      variant='outlined'
                      placeholder='ookeenga@abc.com'
                      size='medium'
                      error={invalid}
                    />
                    {invalid && <div className='text-red-500 text-tiny md:text-sm mt-1'>Wrong email format!</div>}
                  </div>
                )}
              />
            )}
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            loading={!isForgetPass ? isLoadingChangePassword : isLoadingForgotPassword}
            className='w-48 md:w-96'
            onClick={() => {
              handleSubmit((values) => {
                const { currentPassword, newPassword, email } = values;
                if (!isForgetPass) {
                  changePassword({ currentPassword, newPassword });
                } else {
                  forgotPassword({ email, operatingSystem, browserName });
                }
              })();
            }}
          >
            {!isForgetPass ? 'CONFIRM' : 'SUBMIT'}
          </DesignButton>
        </>
      )}
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupChangePassword;
