import React from 'react';
import { DesignButton } from 'components';
import { useIsAccountOwner, useTabs, useWindowDimensions } from 'hooks';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { InventoryCocoons, InventoryHeroes, NFTExchange } from './components';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';

const Inventory = () => {
  const { search } = useLocation();
  const [isAccountOwner] = useIsAccountOwner();
  const { isMobile } = useWindowDimensions();
  const { owner, ...otherSearch } = parse(search);
  const navigate = useNavigate();
  const { address, isLoggedIn } = useSelector(profileSelector);

  React.useEffect(() => {
    if (isLoggedIn && !owner) {
      navigate({
        search: stringify({ owner: address, ...otherSearch }, { skipNull: true, skipEmptyString: true }),
      });
    }
  }, [address, isLoggedIn, navigate, otherSearch, owner]);

  const tabs = [
    {
      code: 'cocoons',
      label: `Cocoons`,
      component: <InventoryCocoons address={(owner as string) ?? address} />,
    },
    {
      code: 'heroes',
      label: `Heroes`,
      component: <InventoryHeroes address={(owner as string) ?? address} />,
    },
    ...(isLoggedIn && isAccountOwner
      ? [
          {
            code: 'nft-exchange',
            label: `Binance NFTs`,
            component: <NFTExchange />,
          },
        ]
      : []),
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <div>
      <div className='md:hidden text-xl text-color-secondary text-center font-bold mb-4'>Inventory</div>
      <div className='flex gap-3 justify-center md:justify-start mb-7'>
        {tabs.map((tab) => (
          <DesignButton
            key={tab.code}
            design={tab.code === activeTab ? 'yellow' : 'gray'}
            size={isMobile ? 'small' : 'medium'}
            className='w-28 md:w-60 text-xs md:text-xl'
            onClick={(event) => onChangeTab(event, tab.code)}
          >
            {tab.label}
          </DesignButton>
        ))}
      </div>
      {tabs.map((tab) => (
        <div hidden={tab.code !== activeTab} key={tab.code}>
          {tab.component}
        </div>
      ))}
    </div>
  );
};

export default Inventory;
