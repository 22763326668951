import { Container, Divider } from '@mui/material';
import { DesignButton } from 'components';
import { useTabs, useWindowDimensions } from 'hooks';
import { Duration } from 'luxon';
import { UserOverviewType } from 'models/User';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from 'reducers';
import { profileSelector } from 'reducers/profile';
import { openPopupRegister } from 'reducers/register';
import { scholarshipRoute } from 'routes';
import { userService } from 'services';
import { customizeFloatNumber } from 'utils/common';
import TableApplication from './TableApplication';
import TableAssigned from './TableAssigned';
import TableAssignedEnded from './TableAssignedEnded';
import TableListing from './TableListing';

const NumberOverview = ({ value, label }: { value: string; label: string }) => (
  <div className='flex flex-col gap-1 items-center' style={{ width: 230 }}>
    <div className='text-2xl md:text-huge font-extrabold'>{value}</div>
    <div className='text-color-primary font-black'>{label}</div>
  </div>
);

const Title = ({ value }: { value: string }) => <div className='font-skadi text-xl md:text-3xl mb-4'>{value}</div>;

const CustomDivider = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <Divider orientation={isMobile ? 'horizontal' : 'vertical'} variant='middle' className='w-10 md:w-auto md:h-10' />
  );
};

const realTabs = (overview?: UserOverviewType) => [
  {
    code: 'assigned',
    label: `Assigned`,
    component: <TableAssigned overview={overview} />,
    border: false,
  },
  {
    code: 'listing',
    label: `Listing`,
    component: <TableListing />,
    border: false,
  },
  {
    code: 'application',
    label: `Application`,
    component: <TableApplication />,
    border: true,
  },
  {
    code: 'assignedEnded',
    label: `Ended`,
    component: <TableAssignedEnded overview={overview} />,
    border: true,
  },
];

const TabContent = ({ overview }: { overview?: UserOverviewType }) => {
  const { isMobile } = useWindowDimensions();
  const tabs = useMemo(() => realTabs(overview), [overview]);

  const [activeTab, onChangeTab] = useTabs(tabs, 'tab-manager');

  return (
    <div className='bg-color-browny py-5 md:p-5' style={{ borderRadius: isMobile ? 0 : 10 }}>
      <div className='px-4 md:px-0'>
        <div
          className='flex justify-center md:w-fit gap-2 md:gap-4 px-4 mb-3 md:mb-6'
          style={{ borderRadius: '10px', background: '#3B2B1F' }}
        >
          {tabs.map((tab, index) => {
            const selected = activeTab === tab.code;

            return (
              <div className='flex gap-2 md:gap-4' key={index}>
                {tab.border && (
                  <Divider
                    orientation='vertical'
                    variant='middle'
                    flexItem
                    style={{ borderRight: '1px solid #655547' }}
                  />
                )}
                <div
                  className='text-sm md:text-base font-black cursor-pointer px-2 py-3'
                  style={{
                    borderBottom: selected ? '2px solid #F07300' : '',
                    color: selected ? '#FF9C4A' : '#655547',
                  }}
                  onClick={(event) => onChangeTab(event, tab.code)}
                >
                  {tab.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {tabs.map((tab) => (
        <div hidden={tab.code !== activeTab} key={tab.code}>
          {tab.component}
        </div>
      ))}
    </div>
  );
};

const Manager = () => {
  const { registered } = useSelector(profileSelector);
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();

  const { data: overview } = useQuery(['userService.fetchUserOverview'], userService.fetchUserOverview, {
    enabled: registered,
  });

  return (
    <>
      <Container className='text-color-secondary pt-14'>
        <Title value='Overview' />
        <div className='md:grid md:grid-cols-3 gap-5 mb-14'>
          <div className='flex flex-col md:col-span-2 gap-5 mb-11 md:mb-0'>
            <div
              className='bg-color-browny px-6 py-8 flex flex-col md:flex-row gap-6 md:gap-0 justify-center items-center'
              style={{ borderRadius: 10 }}
            >
              <NumberOverview value={`${overview?.totalHeroCount ?? 0}`} label='Heroes' />
              <CustomDivider />
              <NumberOverview value={`${overview?.totalScholarshipCount ?? 0}`} label='Scholarships' />
              <CustomDivider />
              <NumberOverview
                value={`${Duration.fromObject({ seconds: overview?.totalPlayedSeconds ?? 0 }).toFormat('h')} hrs`}
                label='Total time played'
              />
            </div>
            <div className='bg-color-browny flex flex-col items-center justify-center p-8' style={{ borderRadius: 10 }}>
              <div className='text-color-primary font-black mb-1 md:text-2xl'>In-game Balance</div>
              <div className='flex gap-3 items-center text-2xl md:text-huge font-extrabold text-color-greenish'>
                <img
                  src={require(`assets/icons/KAB-token.png`).default}
                  style={{ width: isMobile ? 32 : 48, height: isMobile ? 32 : 48 }}
                />
                <div>
                  {Number(customizeFloatNumber(overview?.kab ?? 0)).toLocaleString()}{' '}
                  <span className='text-base md:text-xl'>gKAB</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='min-h-full px-6 md:px-10 py-10'
            style={{
              background: `url(${
                require('assets/images/background-new-scholarship.png').default
              }) no-repeat center center / cover`,
              borderRadius: 10,
            }}
          >
            <div
              className='text-3xl md:text-huge font-skadi font-bold mb-7'
              style={{ maxWidth: registered ? 300 : '100%' }}
            >
              {registered ? `Let's start your scholarship` : 'Create main-account to start scholarship'}
            </div>
            {registered ? (
              <DesignButton
                fullWidth
                design='yellow'
                size={isMobile ? 'medium' : 'large'}
                className='w-64'
                onClick={() => navigate(scholarshipRoute.createNew.url())}
              >
                NEW SCHOLARSHIP
              </DesignButton>
            ) : (
              <DesignButton
                fullWidth
                design='yellow'
                size={isMobile ? 'medium' : 'large'}
                className='w-64'
                onClick={() => store.dispatch(openPopupRegister())}
              >
                CREATE ACCOUNT
              </DesignButton>
            )}
          </div>
        </div>
        <Title value='As a manager' />
        {!isMobile && <TabContent overview={overview} />}
      </Container>
      {isMobile && <TabContent overview={overview} />}
    </>
  );
};

export default Manager;
