import { CardMedia, Divider, Paper } from '@mui/material';
import { CocoonType } from 'models/Cocoon';
import { useWindowDimensions } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { currencyFormat } from 'utils/common';

const CardCocoon = ({ item, notDisplaySale }: { item: CocoonType; notDisplaySale?: boolean }) => {
  const { isMobile } = useWindowDimensions();
  const isSale = !!item?.sale;
  const isBreededCocoon = item?.type === 'standard-cocoon';
  const isOpenable = (item?.attributes[2]?.value ?? 0) < DateTime.now().toSeconds();

  const [remainTime, setRemainTime] = useState('00:00:00');
  const timerRef = useRef<any>();

  const PriceBox = () => (
    <>
      {!notDisplaySale && (
        <div className='flex justify-center items-center w-20 md:w-full'>
          {isSale ? (
            <div className='flex items-center gap-1 md:gap-2 text-xs md:text-2xl text-color-secondary font-bold break-all px-1'>
              <img src={require('assets/icons/OKG-token.png').default} className='h-4 md:h-5' />
              {currencyFormat(Number(item?.sale?.price ?? '0'))}
            </div>
          ) : (
            <div className='text-color-primary text-xs md:text-base font-bold mb-2'>Not listed</div>
          )}
        </div>
      )}
    </>
  );

  const RemainTime = () => (
    <div className='text-color-primary text-xs md:text-base font-bold mb-2 w-20 md:w-full flex items-center justify-center'>
      {remainTime}
    </div>
  );

  const getTime = useCallback(() => {
    const newRemainTime = (item?.attributes[2]?.value ?? 0) - DateTime.now().toSeconds();
    setRemainTime(Duration.fromObject({ seconds: newRemainTime }).toFormat('hh:mm:ss'));
  }, [item?.attributes]);

  useEffect(() => {
    if (!isOpenable) {
      timerRef.current = setInterval(() => getTime(), 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [getTime, isOpenable]);

  return (
    <>
      {isMobile ? (
        <div className='flex bg-color-dark w-full' style={{ border: '0.5px solid #574239', borderRadius: 5 }}>
          <div className='flex flex-1 w-full p-2.5' style={{ gap: 18, borderRight: '0.5px solid #574239' }}>
            <CardMedia
              image={item.image}
              className='max-w-full'
              style={{ border: '1.5px solid #FFFFFF', borderRadius: 10, width: 88, height: 88 }}
            />
            <div className='flex flex-col gap-1 justify-center text-color-secondary'>
              <div className='font-semibold text-xs uppercase break-all'>{item.name}</div>
              <div className='text-tiny'>ID: {item.tokenId}</div>
            </div>
          </div>
          {isBreededCocoon ? isOpenable ? <PriceBox /> : <RemainTime /> : <PriceBox />}
        </div>
      ) : (
        <Paper className='card-hover w-full' elevation={4}>
          <div
            style={{ background: `url(${require('assets/images/frame-market-hero.png').default})` }}
            className='absolute inset-0 card-background'
          />
          <CardMedia image={item.image} className='h-60 m-1 mb-0'>
            <div />
          </CardMedia>
          <div className='pt-2 pb-5' style={{ backgroundColor: '#33221B' }}>
            <div className='font-bold text-center text-color-secondary'>{item.name}</div>
            <div className='text-color-secondary text-center mb-2'>ID: {item.tokenId}</div>
            {!notDisplaySale && <Divider className='w-full border-color-brown border-b-2 mb-2' />}
            {isBreededCocoon ? isOpenable ? <PriceBox /> : <RemainTime /> : <PriceBox />}
          </div>
        </Paper>
      )}
    </>
  );
};

export default CardCocoon;
