import { CloseButton, DesignButton } from 'components';
import { PopupController } from 'models/Common';
import { CocoonType } from 'models/Cocoon';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { nftService } from 'services';
import { useWindowDimensions } from 'hooks';

type PopupProps = PopupController & {
  item: CocoonType;
};

const PopupConfirm = ({ item, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();

  const { mutate: deleteSaleCocoon } = useMutation(nftService.deleteSaleCocoon, {
    onSuccess: () => {
      enqueueSnackbar('Cancel sale cocoon successfully!', { variant: 'success' });
      if (!!onSuccess) {
        onSuccess();
      }
      onClose();
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Cancel sale cocoon failed!', { variant: 'error' });
    },
  });

  return (
    <div className='flex flex-col items-center text-color-secondary py-7 md:p-12'>
      <span className='text-xl md:text-3xl font-skadi font-normal md:mb-4'>DELIST ITEM?</span>
      <div className='text-xs md:text-xl text-center mb-4 md:mb-14'>
        This item will be instantly REMOVED from Marketplace and return to your Inventory
      </div>
      <div className='w-48 md:w-96'>
        <DesignButton
          fullWidth
          design='yellow'
          size={isMobile ? 'medium' : 'large'}
          onClick={() => deleteSaleCocoon({ saleId: item?.sale?._id! })}
        >
          CONFIRM
        </DesignButton>
      </div>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupConfirm;
