import { Add } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const accordContents = [
  {
    title: '1.What is Ookeenga Referral Program?',
    description: (
      <div>
        Ookeenga is releasing a Referral Program for our partners, who can bring in real users who play the games. All
        they have to do is obtain unique referral codes from the OKG team and share it with their user base. When their
        players sign up using the referral code and actually play the game, the referrer will gain benefits from share
        of NFT revenue
      </div>
    ),
  },
  {
    title: '2. What is my benefits from Ookeenga Referral Program as a REFERRER?',
    description: (
      <div>
        When your friends create accounts using your referral link or code then trade on Ookeenga marketplace, you will
        get commission for every purchase from your friends. In additional, you will get rewards on total spending from
        your friends in game.
        <br />
        <br />
        Rewards are divided into 2 types:
        <br />
        Token rewards: the amount of OKG rewards which calculated on the trading volume from qualified referrals on OKG
        marketplace. It will be airdropped to your crypto wallet twice per month.
        <br />
        In-game rewards: the amount of KAB rewards which calculated on the spending volume from qualified referrals in
        OKG game. It will be credited to your game account twice per month.
        <br />
        <br />
        Detail commission scheme here (external to medium)
        <br />
        <br />
        Note: A referral code must be used when first opening an Ookeenga game account. In case your friends created
        account without using your referral code, the commission cannot be collected.
      </div>
    ),
  },
  {
    title: '3. What is my benefit from Ookeenga Referral Program as a REFEREE?',
    description: (
      <div>
        When you complete setting up your account by accepting a referral invite from your friend, you may receive the
        amount of token rewards. It will be unlock when your trading volume on OKG marketplace reaches xxx OKG.
      </div>
    ),
  },
  {
    title: '4. How are my referral rewards paid out?',
    description: (
      <div>
        Your rewards will be airdropped/credited by Ookeenga Team.
        <br />
        <br />
        Token rewards will be airdropped to your crypto wallet twice per month.
        <br />
        In-game rewards will be credited to your game account twice per month.
        <br />
        Referee rewards will be unlocked when your trading volume on OKG marketplace reaches xxx OKG and will be
        airdropped to your crypto wallet at the end of that month.
      </div>
    ),
  },
  {
    title: '5. How do I refer someone to join Ookeenga?',
    description: (
      <div>
        Follow the simple steps to refer your friends and earn rewards:
        <br />
        Connect your wallet
        <br />
        Land on Referral Program page
        <br />
        Find & share you referral link or code with your friends
      </div>
    ),
  },
  {
    title: '6. When are my referral rewards updated?',
    description: (
      <div>
        Referral rewards will be calculated at 00:00 UTC based on the trading data of the previous day and the updated
        number will be synchronized at 02:00 UTC every day.
        <br />
        <br />
        Note: Reward data is displayed for your reference only. If you have any questions, please contact our customer
        support at ...@ookeenga.io
      </div>
    ),
  },
];

const SingleAccord = ({ title, description }: { title: string; description: string | JSX.Element }) => (
  <Accordion className='bg-none bg-transparent shadow-none'>
    <AccordionSummary
      className='bg-none bg-transparent text-color-secondary text-2xl font-black px-0 pt-6 pb-14'
      style={{ borderBottom: '1px solid #574239' }}
      expandIcon={<Add className='text-white' />}
    >
      {title}
    </AccordionSummary>
    <AccordionDetails
      className='bg-none bg-transparent p-6 md:p-8 text-color-secondary whitespace-pre-wrap text-xs md:text-xl'
      style={{}}
    >
      {description}
    </AccordionDetails>
  </Accordion>
);

const ReferralFAQs = () => {
  return (
    <div
      className='bg-color-dark text-color-secondary px-3 md:px-10 py-3 md:py-14'
      style={{ fontFamily: 'Arial', borderRadius: 10 }}
    >
      <div className='text-huge font-black'>FAQs</div>
      {accordContents.map((accord, index) => (
        <SingleAccord key={index} title={accord.title} description={accord.description} />
      ))}
    </div>
  );
};

export default ReferralFAQs;
