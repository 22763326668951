import { AppTheme } from 'containers';
import { default as jwtDecode } from 'jwt-decode';
import { SnackbarProvider } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profile';
import { queryClient, walletService } from 'services';

type ContainerType = {
  children: React.ReactNode;
};

const Container = ({ children }: ContainerType) => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);

  const firstLoad = useCallback(async () => {
    try {
      await walletService.connectProvider();
      const profile = JSON.parse(localStorage.getItem('profile')!);
      if (!profile) return;

      jwtDecode(profile.token);
      dispatch(signIn(profile));
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsReady(true);
    }
  }, [dispatch]);

  useEffect(() => {
    firstLoad();
  }, [firstLoad]);

  return (
    <SnackbarProvider preventDuplicate={false} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <QueryClientProvider client={queryClient}>
        <AppTheme>{isReady && children}</AppTheme>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

export default Container;
