import { Button, Dialog, styled } from '@mui/material';
import { AppFooter, AppHeader } from 'containers';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { registerSelector } from 'reducers/register';
import { publicRoute } from 'routes';
import PopupRegister from './PopupRegister';
import ScrollToTop from './ScrollToTop';

const ButtonFeedback = styled(Button)`
  background: url(${require('assets/images/button-feedback.png').default}) no-repeat center center;
  background-size: 100% 100%;
  height: 120px;
  width: 44px;
  padding: 0px;
  min-width: 0;
`;

const PublicLayout = () => {
  const { isOpenPopupRegister } = useSelector(registerSelector);

  return (
    <div className='min-h-screen relative'>
      <ScrollToTop />
      <ButtonFeedback
        className='hidden md:block fixed bottom-72 -right-1'
        onClick={() => window.open('https://forms.gle/A69m5AKSZDm1hYtY8', '_blank')}
        style={{ zIndex: 99 }}
      />
      <AppHeader />
      <div className='min-h-screen flex'>
        <div className='flex-1' style={{ backgroundColor: '#060302' }}>
          <Suspense fallback={<div>Loading, please wait...</div>}>
            <Routes>
              {Object.values(publicRoute).map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
              <Route path='*' element={<Navigate to={publicRoute.marketplace.path} />} />
            </Routes>
          </Suspense>
          {isOpenPopupRegister && (
            <Dialog maxWidth='md' open={isOpenPopupRegister}>
              <PopupRegister />
            </Dialog>
          )}
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default PublicLayout;
