import { client } from './axios';
import { CheckInInfoType, RedeemHistoryParams, RedeemHistoryResType } from 'models/CheckIn';

const checkIn = () => client.post(`/api/check-in-redeem/check-in`);
const redeem = () => client.post(`/api/check-in-redeem/redeem`);
const getRedeemStatus = (): Promise<CheckInInfoType> => client.get(`/api/check-in-redeem/status`);
const getRedeemHistory = (params?: RedeemHistoryParams): Promise<RedeemHistoryResType> =>
  client.get(`/api/check-in-redeem/histories`, { params });

export default {
  checkIn,
  redeem,
  getRedeemStatus,
  getRedeemHistory,
};
