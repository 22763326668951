import { isNil } from 'ramda';
import { useLayoutEffect, useRef, useState } from 'react';
import './SlidingText.scss';

const checkLength = (containerEl: HTMLDivElement) => {
  const holderEl: HTMLDivElement | null = containerEl.querySelector('[child-holder]');
  if ((holderEl?.offsetWidth ?? 0) > (containerEl.offsetWidth ?? 0)) {
    return true;
  } else {
    return false;
  }
};

const SlidingText = ({ text }: { text: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const containerEl = containerRef.current;
    setIsOverflow(checkLength(containerEl));
  }, []);

  return (
    <div className='container-sliding' ref={containerRef}>
      <div className={isNil(isOverflow) ? 'full-length' : isOverflow ? 'holder' : 'normal-text'} child-holder=''>
        {text}
      </div>
      <div className={['sliding-text', isOverflow ? '' : 'hidden'].join(' ')}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default SlidingText;
