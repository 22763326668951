import { Typography } from '@mui/material';
import React from 'react';

const ScholarStatus = ({ status = '', color = '', statusText = '', ...props }) => {
  switch (status) {
    case 'approved':
      color = '#6CDD37';
      statusText = 'Approved';
      break;
    case 'rejected':
      color = '#FF613F';
      statusText = 'Rejected';
      break;
    case 'canceled':
      color = '#FFAC26';
      statusText = 'Canceled';
      break;
    case 'none':
      statusText = 'Pending';
      color = '#FFAC26';
      break;
    default:
      color = '#6CDD37';
      statusText = 'Open';
  }
  return (
    <Typography
      sx={{
        color: { color },
      }}
      {...props}
    >
      {statusText}
    </Typography>
  );
};

export default ScholarStatus;
