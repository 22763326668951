import { IconButton, Link as MuiLink } from '@mui/material';
import { Copiable } from 'components';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { userService } from 'services';
import { ReferralFAQs } from './components';
import { stringify } from 'query-string';

const CopiableCode = ({ title, text }: { title: string; text: string }) => (
  <div className='p-4 w-full' style={{ background: '#4D3723', borderRadius: 10 }}>
    <div className='font-extrabold mb-1.5'>{title}</div>
    <div className='bg-color-primary px-4 py-2' style={{ borderRadius: 10 }}>
      <Copiable address={text} className='font-extrabold justify-between text-color-brown' />
    </div>
  </div>
);

const Referral = () => {
  const { address } = useSelector(profileSelector);

  const { data: info } = useQuery(
    ['userService.fetchUserInfo', { address }],
    ({ queryKey }) => {
      const { address } = queryKey[1] as any;
      return userService.fetchUserInfo(address ?? '');
    },
    {
      enabled: !!address,
    },
  );

  const referralUrl = `${window.location.host}/refer/${info?.refCode}`;
  const queryStringFB = stringify({ u: referralUrl });
  const queryString = stringify({ url: referralUrl });

  return (
    <div>
      <div className='md:hidden text-xl text-color-secondary text-center font-bold mb-4'>Referral</div>
      <div
        className='bg-color-dark text-color-secondary px-3 md:px-10 py-3 md:py-14 mb-14'
        style={{ borderRadius: 10 }}
      >
        <div className='font-skadi text-huge font-bold text-center mb-10'>
          Refer new Players to Earn Crypto Together!
        </div>
        <div className='p-5' style={{ background: '#33221BCC', border: '1px solid #574239', borderRadius: 16 }}>
          <img src={require(`assets/images/referral-banner-1.png`).default} className='rounded-2xl mb-5' />
          <div className='flex gap-5 mb-5'>
            <CopiableCode title='Referrral Link' text={referralUrl} />
            <CopiableCode title='Referral code' text={info?.refCode ?? ''} />
          </div>
          <div className='text-color-primary font-bold text-center italic'>Share with friends:</div>
          <div className='flex justify-center gap-2'>
            {[
              {
                icon: 'Telegram',
                url: `https://telegram.me/share/?${queryString}`,
              },
              { icon: 'Twitter', url: `https://twitter.com/intent/tweet?${queryString}` },
              { icon: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?${queryStringFB}` },
            ].map((item, index) => (
              <MuiLink key={index} href={item.url} target='_blank'>
                <IconButton>
                  <img src={require(`assets/socials/${item.icon}.png`).default} className='w-8' />
                </IconButton>
              </MuiLink>
            ))}
          </div>
        </div>
      </div>
      <ReferralFAQs />
    </div>
  );
};

export default Referral;
