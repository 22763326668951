import { Button, Modal, Pagination, PaginationItem, styled, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { ArrowDropDown, ArrowDropUp, Check, Clear, InfoOutlined } from '@mui/icons-material';
import { customizeFloatNumber, shorten } from 'utils/common';
import { Duration } from 'luxon';
import { CustomMUIDataTable, ExpansionCell, NoItem, tableHeadRender } from './TableAssigned';
import { memo, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { useMutation, useQuery } from 'react-query';
import { scholarshipService, userService } from 'services';
import { ApproveAplicationParams, GetScholarApplicationParams } from 'models/Scholarship';
import { useSnackbar } from 'notistack';
import { SCHOLARSHIP_CONTRACT } from 'env';
import { ScholarshipContract } from 'contracts';
import PopupProcessing from './PopupProcessing';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from 'hooks';

const CustomMUIDataTableApplication = styled(MUIDataTable)`
  background: transparent !important;
  border: 1px solid #45342c;
  border-radius: 0px 0px 8px 8px;
  & .tss-34eyxm-MUIDataTableToolbarSelect-root {
    /* visibility: hidden !important; */
    display: none !important;
  }
  & .MuiTableCell-root {
    border-bottom: 1px solid #45342c;
    color: #f1e9dc;
    font-size: 16px;
    line-height: 22px;
  }
  & .MuiTableCell-head {
    border-bottom: 1px solid #45342c;
    color: #f1e9dc;
    background: #170a02cc !important;
  }
  & .MuiTableCell-footer {
    border: none !important;
  }
`;

const tableHeadRenderWithBorder = (
  columnMeta: any,
  handleToggleColumn: any,
  sortOrder: any,
  content?: () => JSX.Element,
  borderLeft?: boolean,
) => {
  const sort = columnMeta.sort;
  const isSortedRow = sortOrder.name === columnMeta.name;

  return (
    <th
      key={columnMeta?.index}
      id={`tableApplication-${columnMeta?.name}`}
      className='MuiTableCell-head p-4 text-color-primary font-black'
      style={{ height: 69.5, ...(borderLeft ? { borderLeft: '1px solid #574239' } : {}) }}
    >
      <div className='flex items-center'>
        <div>{content ? content() : columnMeta?.label}</div>
        {sort && (
          <div
            className={`${sort ? 'cursor-pointer' : ''}`}
            onClick={() => (sort ? handleToggleColumn(columnMeta?.index) : {})}
          >
            {isSortedRow ? (
              sortOrder.direction === 'asc' ? (
                <ArrowDropUp className='block' />
              ) : (
                <ArrowDropDown className='block' />
              )
            ) : (
              <>
                <ArrowDropUp className='block -mb-4' />
                <ArrowDropDown className='block' />
              </>
            )}
          </div>
        )}
      </div>
    </th>
  );
};

const defaultSearch = {
  limit: 10,
  offset: 0,
};

const TableApplication = () => {
  const location = useLocation();
  const { isMobile } = useWindowDimensions();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { address } = useSelector(profileSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, setDataSearch] = useState({ ...defaultSearch, owner: address?.toLocaleLowerCase() });

  const { data: applications, refetch } = useQuery(
    ['scholarshipService.getScholarApplication', dataSearch],
    async ({ queryKey }) => {
      const applicationsRes = await scholarshipService.getScholarApplication(
        queryKey[1] as GetScholarApplicationParams,
      );
      const fetchScholarName = applicationsRes.scholarAchievements.map((item) =>
        userService.fetchUserInfo(item.scholarWeb3Address ?? ''),
      );
      const scholarNames = await Promise.all(fetchScholarName);
      const newScholarAchievements = applicationsRes.scholarAchievements.map((item, index) => ({
        ...item,
        scholarName: scholarNames[index].username,
      }));
      const newScholarshipApps = applicationsRes.scholarshipApps.map((item, index) => {
        const newScholarAchievement = newScholarAchievements.find(
          (achievement) => achievement.scholarWeb3Address === item.scholarAddr,
        );

        return {
          ...item,
          scholarAchievement: newScholarAchievement,
          totalPlayedSeconds: newScholarAchievement?.totalPlayedSeconds,
          totalEarnKab: newScholarAchievement?.totalEarnKab,
          winRate: newScholarAchievement?.winRate,
          ratio: item.scholarship.ratio,
          scholarshipName: item.scholarship.scholarshipName,
        };
      });
      return { ...applicationsRes, scholarshipApps: newScholarshipApps, scholarAchievements: newScholarAchievements };
    },
    { keepPreviousData: true },
  );

  const { mutate: approveApplication, isLoading: isLoadingApprove } = useMutation(
    async ({
      param,
      scholarshipId,
      heroTokenIds,
      scholarAddress,
    }: {
      param: ApproveAplicationParams;
      scholarshipId: string;
      heroTokenIds: string[];
      scholarAddress: string;
    }) => {
      await scholarshipService.approveApplication(param);
      const signature = await scholarshipService.getSignature({ scholarshipId: scholarshipId });
      await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
        .methods.scholarship(heroTokenIds, scholarshipId, scholarAddress, signature.signature)
        .send({
          from: address,
        });
      await scholarshipService.activateScholarship({ scholarshipId: scholarshipId });
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar('Approve successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Approve failed!', { variant: 'error' });
      },
    },
  );

  const { mutate: rejectApplication, isLoading: isLoadingReject } = useMutation(scholarshipService.rejectApplication, {
    onSuccess: () => {
      refetch();
      enqueueSnackbar('Reject successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Reject failed!', { variant: 'error' });
    },
  });

  const HoverButton = memo(({ rowIndex }: { rowIndex: number }) => {
    const scholarshipApp = applications?.scholarshipApps[rowIndex];

    return (
      <div
        className='flex gap-2 h-full px-4 py-2 items-center justify-center'
        style={{
          background: isMobile ? '#271911' : '#382B24',
          minWidth: isMobile ? 'auto' : 263,
        }}
      >
        <Button
          variant='contained'
          startIcon={<Check />}
          onClick={() =>
            approveApplication({
              param: { id: scholarshipApp?._id ?? '' },
              scholarshipId: scholarshipApp?.scholarshipId ?? '',
              heroTokenIds: scholarshipApp?.scholarship.heroTokenIds ?? [],
              scholarAddress: scholarshipApp?.scholarAddr ?? '',
            })
          }
        >
          Approve
        </Button>
        <Button
          variant='contained'
          startIcon={<Clear />}
          onClick={() => rejectApplication({ id: scholarshipApp?._id ?? '' })}
        >
          Reject
        </Button>
      </div>
    );
  });

  const columns = [
    {
      name: 'scholarAddr',
      label: 'Scholar',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRenderWithBorder,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const dataIndex = currentTableData[rowIndex].index;
          const scholarshipApp = applications?.scholarshipApps[dataIndex];
          const scholarAchievement = scholarshipApp?.scholarAchievement;

          return (
            <div>
              <div className='flex flex-col gap-1'>
                {!!scholarAchievement && (
                  <Tooltip
                    placement='top'
                    title={<div className='text-lg text-color-primary'>{scholarAchievement.scholarName}</div>}
                  >
                    <div>{shorten(scholarAchievement?.scholarName ?? '', 15, 0, 15)}</div>
                  </Tooltip>
                )}
                <div className='text-sm' style={{ color: '#94794D' }}>
                  {shorten(value)}
                </div>
              </div>
              <div
                id={`hoverRow-tableApplication-${dataIndex}`}
                className='absolute right-0'
                style={{
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  display: 'none',
                }}
              >
                <HoverButton rowIndex={dataIndex} />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'totalPlayedSeconds',
      label: 'Time Played',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRenderWithBorder,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => (
          <div>{`${Duration.fromObject({ seconds: value ?? 0 }).toFormat('h')} hours played`}</div>
        ),
      },
    },
    {
      name: 'totalEarnKab',
      label: 'Total Earned',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRenderWithBorder,
        customBodyRender: (value: any, { rowIndex }: any) => <div>{customizeFloatNumber(value ?? 0)}</div>,
      },
    },
    {
      name: 'winRate',
      label: 'Win Rate',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRenderWithBorder,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const newValue = (value ?? 0) * 100;

          return (
            <div {...(newValue >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(
              newValue,
            )}%`}</div>
          );
        },
      },
    },
    {
      name: 'ratio',
      label: '',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
          tableHeadRenderWithBorder(
            columnMeta,
            handleToggleColumn,
            sortOrder,
            () => (
              <div className='flex gap-1 items-start'>
                Split Rate
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>Split Rate: % to scholar - % to manager</div>
                  }
                  componentsProps={{
                    tooltip: { sx: { background: '#170A02CC !important' } },
                  }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            ),
            true,
          ),
        setCellProps: () => ({
          style: { borderLeft: '1px solid #574239' },
        }),
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const ratio = value ?? 0;

          return <div>{`${ratio}/${100 - ratio}`}</div>;
        },
      },
    },
    {
      name: 'scholarshipName',
      label: 'Apply for',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRenderWithBorder,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          return <div>{shorten(value, 25, 0, 25)}</div>;
        },
      },
    },
  ];

  const mobileColumn = [
    {
      name: 'scholarAddr',
      label: 'Player',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          return <div>{`${shorten(value, 5, 5, 5)}`}</div>;
        },
      },
    },
    {
      name: 'winRate',
      label: 'Win Rate',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const newValue = (value ?? 0) * 100;

          return (
            <div {...(newValue >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(
              newValue,
            )}%`}</div>
          );
        },
      },
    },
    {
      name: 'ratio',
      label: 'Apply for',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const dataIndex = currentTableData[rowIndex].index;
          const ratio = value ?? 0;

          return (
            <div className='flex flex-col gap-1'>
              <div>{shorten(applications?.scholarshipApps[dataIndex].scholarship.scholarshipName, 25, 0, 25)}</div>
              <div className='text-color-primary'>{`${ratio}/${100 - ratio}`}</div>
            </div>
          );
        },
      },
    },
  ];

  const handleRowHover = (rowIndex: any) => {
    let row = document.getElementById('hoverRow-tableApplication-' + rowIndex);
    if (!!row) row!.style.display = 'flex';
  };

  const handleRowHoverLeave = (rowIndex: any) => {
    let row = document.getElementById('hoverRow-tableApplication-' + rowIndex);
    if (!!row) row!.style.display = 'none';
  };

  const [headerWidth, setHeaderWidth] = useState(0);

  const GroupHeader = memo(() => {
    return (
      <div
        className='flex text-center text-color-primary font-black bg-color-dark'
        style={{ border: '1px solid #574239', borderRadius: '8px 8px 0px 0px' }}
      >
        <div className='flex-1 py-6'>PLAYER</div>
        <div className='py-6' style={{ width: headerWidth, borderLeft: '1px solid #574239' }}>
          SCHOLARSHIP
        </div>
      </div>
    );
  });

  const handleResize = () => {
    setHeaderWidth(
      (document.getElementById('tableApplication-ratio')?.offsetWidth ?? 0) +
        (document.getElementById('tableApplication-scholarshipName')?.offsetWidth ?? 0) +
        1,
    );
  };

  useLayoutEffect(() => {
    if (params['tab-manager'] === 'application') {
      window.addEventListener('resize', handleResize);
      setTimeout(() => {
        handleResize();
      }, 0);
    }
  }, [params]);

  return (
    <>
      {!!applications && applications.scholarshipApps.length > 0 ? (
        <>
          <div className='mb-3'>
            {isMobile ? (
              <CustomMUIDataTable
                title=''
                data={applications.scholarshipApps}
                columns={mobileColumn}
                options={{
                  selectableRows: undefined,
                  expandableRows: true,
                  responsive: 'standard',
                  elevation: 0,
                  download: false,
                  filter: false,
                  pagination: false,
                  search: false,
                  print: false,
                  viewColumns: false,
                  renderExpandableRow: (
                    rowData: string[],
                    rowMeta: {
                      dataIndex: number;
                      rowIndex: number;
                    },
                  ) => {
                    const scholarshipApp = applications?.scholarshipApps[rowMeta.dataIndex];

                    return (
                      <tr className='text-xs' style={{ background: '#1C110C' }}>
                        <td />
                        <td className='pr-12 py-2' colSpan={3}>
                          <div className='flex flex-col gap-2'>
                            <ExpansionCell
                              title={`Player's Name`}
                              value={shorten(scholarshipApp?.scholarAchievement?.scholarName ?? '', 25, 0, 25)}
                            />
                            <ExpansionCell
                              title={`Player's TIme Played`}
                              value={`${Duration.fromObject({
                                seconds: scholarshipApp?.totalPlayedSeconds ?? 0,
                              }).toFormat('h')} hours`}
                              evenChild
                            />
                            <ExpansionCell
                              title={`Player's Earned`}
                              value={`${Number(
                                customizeFloatNumber(scholarshipApp?.totalEarnKab ?? 0),
                              ).toLocaleString()}`}
                            />
                            <HoverButton rowIndex={rowMeta.dataIndex} />
                          </div>
                        </td>
                      </tr>
                    );
                  },
                }}
              />
            ) : (
              <>
                <GroupHeader />
                <CustomMUIDataTableApplication
                  title=''
                  data={applications.scholarshipApps}
                  columns={columns}
                  options={{
                    elevation: 0,
                    selectableRows: undefined,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    setRowProps: (row, dataIndex, rowIndex) => {
                      return {
                        className: 'relative',
                        onMouseEnter: () => handleRowHover(dataIndex),
                        onMouseLeave: () => handleRowHoverLeave(dataIndex),
                      };
                    },
                  }}
                />
              </>
            )}
          </div>
          <div className='flex justify-center'>
            <Pagination
              size='large'
              color='secondary'
              page={dataSearch.offset / dataSearch.limit + 1}
              count={Math.ceil(applications.total / dataSearch.limit)}
              onChange={(event, page) => {
                if (page > 0) setDataSearch({ ...dataSearch, offset: (page - 1) * dataSearch.limit });
              }}
              renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
            />
          </div>
          <Modal
            open={isLoadingApprove || isLoadingReject}
            style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}
          >
            <>
              <PopupProcessing />
            </>
          </Modal>
        </>
      ) : (
        <NoItem />
      )}
    </>
  );
};

export default TableApplication;
