import { Add, Clear, Close, KeyboardArrowLeft } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  styled,
  TextField,
} from '@mui/material';
import { DesignButton } from 'components';
import { ScholarshipContract } from 'contracts';
import { OKG_HERO, SCHOLARSHIP_CONTRACT } from 'env';
import { useSearch, useWindowDimensions } from 'hooks';
import { HeroType } from 'models/Hero';
import { CreateScholarshipParams, GetScholarshipByIdParams } from 'models/Scholarship';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { publicRoute, scholarshipRoute } from 'routes';
import { nftService, scholarshipService } from 'services';
import { CardHeroMini } from 'views/Home/cards';
import PopupCreateSubAcc from './PopupCreateSubAcc';
import PopupProcessing from './PopupProcessing';

export const InputField = styled(TextField)`
  box-shadow: 0px -5px #705439;
  border-radius: 10px;
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-input {
    font-weight: 800;
    color: #463024;
    padding: 11px 20px;
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
  & .MuiSvgIcon-root {
    color: #705439 !important;
  }
  & .Mui-disabled {
    color: #463024 !important;
    -webkit-text-fill-color: #463024 !important;
  }
`;

const CustomMenuItem = styled(MenuItem)`
  font-weight: 800;
  color: #392609;
  @media (max-width: 800px) {
    font-size: 12px;
    padding: 0px 16px;
    min-height: 32px;
  }
`;

const CustomTextField = styled(TextField)`
  background: #432a1e;
  box-shadow: 0px 4px 4px rgba(26, 14, 8, 0.25);
  border-radius: 8px;
  width: 100%;
  color: #f1e9dc;
  fieldset {
    border: none;
    border-radius: 8px;
  }
  .MuiOutlinedInput-input {
    font-size: 13px !important;
    font-weight: 900 !important;
    @media (max-width: 800px) {
    }
  }
`;

const selectProps = { MenuProps: { MenuListProps: { style: { background: '#E4C496' } } } };

export const Title = ({ name, required }: { name: string; required?: boolean }) => (
  <div className='text-sm md:text-base text-color-primary font-black mb-2'>
    {name} {required && <span className='text-red-700'>*</span>}
  </div>
);

const orderByList = [
  { name: 'By Rank', code: 'rank' },
  { name: 'By Level', code: 'level' },
  { name: 'By Breed Count', code: 'breedCount' },
  { name: 'By Combat Power', code: 'power' },
];

const orderDirectionList = [
  { name: 'High to low', code: 'true' },
  { name: 'Low to high', code: 'false' },
];

const TitleSection = ({ text }: { text: string }) => (
  <div
    className='font-skadi font-bold text-center p-2 text-xl mb-4'
    style={{ background: '#432A1E', borderRadius: 10 }}
  >
    {text}
  </div>
);

const CreateNew = () => {
  const location = useLocation();
  const { id } = useParams();
  const isEdit = location.pathname.includes('edit') && !!id;
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { address, registered } = useSelector(profileSelector);
  const [openCreateSubAcc, setOpenCreateSubAcc] = useState(false);
  const [openChooseHero, setOpenChooseHero] = useState(false);
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false);
  const [isInvalidHeroes, setIsInvalidHeroes] = useState(false);

  const [dataSearch, onSearchChange] = useSearch({
    owner: address,
    limit: 150,
    lockStatus: 'unlocked',
    saleType: 'not_sale',
    classType: '',
    raceType: '',
    orderBy: 'rank',
    desc: true,
  });

  const [orderBy, setOrderBy] = useState(orderByList[0].code);
  const [orderDirection, setOrderDirection] = useState(orderDirectionList[0].code);
  const [classType, setClassType] = useState(' ');
  const [raceType, setRaceType] = useState(' ');

  const { control, handleSubmit, setValue, getValues } = useForm({ mode: 'onChange' });

  const { data: dataClasses } = useQuery(['nftService.fetchHeroClasses'], () => nftService.fetchHeroClasses());
  const { data: dataRaces } = useQuery(['nftService.fetchHeroRaces'], () => nftService.fetchHeroRaces());

  const { data: scholarship } = useQuery(
    ['scholarshipService.getScholarshipById', { scholarshipId: id }],
    async ({ queryKey }) => {
      const scholarshipRes = await scholarshipService.getScholarshipById(queryKey[1] as GetScholarshipByIdParams);
      const fetchExtraHeroes = scholarshipRes.heroes.map((hero) =>
        nftService.getHeroToken({ contract: OKG_HERO!, tokenId: hero.tokenId.toString() }),
      );
      const heroes = await Promise.all(fetchExtraHeroes);
      return { ...scholarshipRes, heroes };
    },
    { enabled: isEdit },
  );

  const defaultData = useMemo(
    () =>
      !!scholarship
        ? {
            scholarshipName: scholarship.scholarshipName,
            username: scholarship.userName,
            ratio: scholarship.ratio,
            scholarWeb3Address: scholarship.scholarWeb3Address,
          }
        : { scholarshipName: '', username: '', ratio: '', scholarWeb3Address: '' },
    [scholarship],
  );
  const [chosenHeros, setChosenHeros] = useState<HeroType[]>([]);

  const { data } = useQuery(
    ['nftService.fetchHeroes', dataSearch],
    ({ queryKey }) => nftService.fetchHeroes(queryKey[1]),
    {
      keepPreviousData: true,
    },
  );
  const { nfts = [] } = data ?? {};

  const allHeroPool = useMemo(() => nfts.concat(scholarship?.heroes ?? []), [nfts, scholarship?.heroes]);

  const heroPool = useMemo(
    () => allHeroPool.filter((item) => !chosenHeros.find((hero) => hero.tokenId === item.tokenId)),
    [allHeroPool, chosenHeros],
  );

  const { data: subAccsRes } = useQuery(['scholarshipService.getSubAccs'], () =>
    scholarshipService.getSubAccs({ address: address!, hasScholarship: false }),
  );

  const { mutate: createScholarship, isLoading: isLoadingCreate } = useMutation(
    async (body: CreateScholarshipParams) => {
      const res = await scholarshipService.createScholarship(body);
      if (!!body.scholarWeb3Address) {
        const signature = await scholarshipService.getSignature({ scholarshipId: res.scholarshipId.scholarshipId });
        await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
          .methods.scholarship(
            signature.heroTokenIds,
            signature.scholarshipId,
            signature.scholarAddr,
            signature.signature,
          )
          .send({
            from: address,
          });
        await scholarshipService.activateScholarship({ scholarshipId: res.scholarshipId.scholarshipId });
      }
    },
    {
      onSuccess: () => {
        setOpenPopupSuccess(true);
        enqueueSnackbar('Create successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Create failed!', { variant: 'error' });
      },
    },
  );

  const { mutate: editScholarship, isLoading: isLoadingEdit } = useMutation(
    async (body: GetScholarshipByIdParams & CreateScholarshipParams) => {
      await scholarshipService.editScholarship(body);
      const scholarshipContractStatus = await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
        .methods.getScolarship(body.scholarshipId)
        .call();
      if (!!body.scholarWeb3Address && scholarship?.scholarshipStatus === 1) {
        if (!scholarshipContractStatus.active) {
          const signature = await scholarshipService.getSignature({ scholarshipId: body.scholarshipId });
          await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
            .methods.scholarship(
              signature.heroTokenIds,
              signature.scholarshipId,
              signature.scholarAddr,
              signature.signature,
            )
            .send({
              from: address,
            });
        }
        await scholarshipService.activateScholarship({ scholarshipId: body.scholarshipId });
      }
    },
    {
      onSuccess: () => {
        setOpenPopupSuccess(true);
        enqueueSnackbar('Edit successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Edit failed!', { variant: 'error' });
      },
    },
  );

  const classTypeSection = useMemo(
    () => (
      <CustomTextField
        select
        SelectProps={selectProps}
        value={classType}
        onChange={(e) => {
          setClassType(e.target.value);
          onSearchChange({ classType: e.target.value.trim() });
        }}
      >
        <CustomMenuItem value=' '>All classes</CustomMenuItem>
        {dataClasses &&
          dataClasses.classes.map((item, index) => (
            <CustomMenuItem key={index} value={item}>
              {item}
            </CustomMenuItem>
          ))}
      </CustomTextField>
    ),
    [classType, dataClasses, onSearchChange],
  );

  const raceTypeSection = useMemo(
    () => (
      <CustomTextField
        select
        SelectProps={selectProps}
        value={raceType}
        onChange={(e) => {
          setRaceType(e.target.value);
          onSearchChange({ raceType: e.target.value.trim() });
        }}
      >
        <CustomMenuItem value=' '>All races</CustomMenuItem>
        {dataRaces &&
          dataRaces.races.map((race, index) => (
            <CustomMenuItem key={index} value={race}>
              {race}
            </CustomMenuItem>
          ))}
      </CustomTextField>
    ),
    [dataRaces, onSearchChange, raceType],
  );

  const orderBySection = useMemo(
    () => (
      <CustomTextField
        select
        SelectProps={selectProps}
        className='rounded-r-none'
        value={orderBy}
        onChange={(e) => {
          setOrderBy(e.target.value);
          onSearchChange({ orderBy: e.target.value });
        }}
      >
        {orderByList.map((item, index) => (
          <CustomMenuItem key={index} value={item.code}>
            {item.name}
          </CustomMenuItem>
        ))}
      </CustomTextField>
    ),
    [onSearchChange, orderBy],
  );

  const orderDirectionSection = useMemo(
    () => (
      <CustomTextField
        select
        SelectProps={selectProps}
        className='rounded-l-none'
        value={orderDirection}
        onChange={(e) => {
          setOrderDirection(e.target.value);
          onSearchChange({ desc: e.target.value?.includes('true') });
        }}
      >
        {orderDirectionList.map((item, index) => (
          <CustomMenuItem key={index} value={item.code}>
            {item.name}
          </CustomMenuItem>
        ))}
      </CustomTextField>
    ),
    [onSearchChange, orderDirection],
  );

  const filterSection = useMemo(
    () => (
      <div className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6 mb-8'>
        {classTypeSection}
        {raceTypeSection}
        <div className='grid grid-cols-2 col-span-2'>
          {orderBySection}
          {orderDirectionSection}
        </div>
      </div>
    ),
    [classTypeSection, orderBySection, orderDirectionSection, raceTypeSection],
  );

  const notEnoughHeroSection = useMemo(
    () =>
      allHeroPool.length < 3 && (
        <div className='text-center font-extrabold text-base mt-2 md:mt-5'>
          <div style={{ color: '#FF613F' }}>Oops, look like you don't have enough heroes.</div>
          <Link to={publicRoute.marketplace.path}>
            <div className='text-color-legendary underline'>Let's get some heroes here</div>
          </Link>
        </div>
      ),
    [allHeroPool.length],
  );

  useEffect(() => {
    if (!registered) {
      navigate(publicRoute.scholarship.path);
    }
  }, [navigate, registered]);

  useEffect(() => {
    if (!!scholarship) {
      Object.entries(defaultData).forEach(([key, value]) => setValue(key, value));
      setChosenHeros(scholarship.heroes);
    }
  }, [defaultData, scholarship, setValue]);

  return (
    <div
      className='min-h-screen py-10 md:py-16 text-color-secondary flex justify-center'
      style={{
        background: `url(${
          require('assets/images/background-create-new-scholarship.png').default
        }) no-repeat top center / contain`,
        backgroundSize: '100% auto',
      }}
    >
      <div
        className='mt-20 p-10 w-full'
        style={{
          borderRadius: 16,
          border: '1px solid #574239',
          backdropFilter: 'blur(20px)',
          maxWidth: 1128,
          background: '#5D382580',
        }}
      >
        <Button
          variant='outlined'
          startIcon={<KeyboardArrowLeft />}
          className='text-white border-white font-extrabold mb-5'
          onClick={() => navigate(scholarshipRoute.management.url())}
        >
          Back
        </Button>
        <div className='font-skadi font-bold text-3xl mb-8'>{`${isEdit ? 'EDIT' : 'CREATE'} SCHOLARSHIP`}</div>
        <div className='flex flex-col md:grid md:grid-cols-5 gap-6 mb-6'>
          <Controller
            name='scholarshipName'
            defaultValue={defaultData.scholarshipName}
            control={control}
            rules={{ required: 'This field is required.' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <div className='col-span-2'>
                <Title name='SCHOLARSHIP NAME' required />
                <InputField
                  {...field}
                  fullWidth
                  variant='outlined'
                  size='medium'
                  placeholder='Ookeenga'
                  error={invalid}
                />
                {invalid && <div className='text-red-500 text-tiny md:text-base mt-1 font-black'>{error?.message}</div>}
              </div>
            )}
          />
          <Controller
            name='username'
            defaultValue={defaultData.username}
            control={control}
            rules={{ required: 'This field is required.' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <div className='col-span-2'>
                <Title name='ACCOUNT' required />
                <InputField
                  {...field}
                  SelectProps={selectProps}
                  select
                  fullWidth
                  variant='outlined'
                  size='medium'
                  disabled={isEdit}
                  error={invalid}
                >
                  {!!scholarship && (
                    <CustomMenuItem value={scholarship.userName}>{scholarship.userName}</CustomMenuItem>
                  )}
                  {subAccsRes?.subAccs.map((subAcc, index) => (
                    <CustomMenuItem key={index} value={subAcc.userName}>
                      {subAcc.userName}
                    </CustomMenuItem>
                  ))}
                  <CustomMenuItem className='hidden' />
                  <div style={{ borderTop: '1px solid #D3B487' }}>
                    <Button
                      fullWidth
                      startIcon={<Add />}
                      onClick={() => setOpenCreateSubAcc(true)}
                      className='font-extrabold text-base justify-start text-color-brown px-4'
                    >
                      Add new
                    </Button>
                  </div>
                </InputField>
                {invalid && <div className='text-red-500 text-tiny md:text-base mt-1 font-black'>{error?.message}</div>}
              </div>
            )}
          />
          <Controller
            name='ratio'
            defaultValue={defaultData.ratio}
            control={control}
            rules={{ required: 'This field is required.', min: 1, max: 100 }}
            render={({ field, fieldState: { invalid, error } }) => {
              let mes = error?.message ?? '';
              if (error?.type === 'min' || error?.type === 'max') {
                mes = 'Only allow to input from 0 to 100';
              }

              return (
                <div className='col-span-1'>
                  <Title name='% TO SCHOLAR' required />
                  <InputField
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span className='font-extrabold' style={{ color: '#8F7A6E' }}>
                            %
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant='outlined'
                    size='medium'
                    error={invalid}
                  />
                  {invalid && <div className='text-red-500 text-tiny md:text-base mt-1 font-black'>{mes}</div>}
                </div>
              );
            }}
          />
        </div>
        <Controller
          name='scholarWeb3Address'
          defaultValue={defaultData.scholarWeb3Address}
          control={control}
          rules={{ pattern: /^0x[a-fA-F0-9]{40}$/g }}
          render={({ field, fieldState: { invalid, error } }) => (
            <div className='mb-12'>
              <Title name='SCHOLAR WALLET' />
              <InputField {...field} fullWidth variant='outlined' size='medium' disabled={isEdit} error={invalid} />
              {invalid && <div className='text-red-500 text-tiny md:text-base mt-1 font-black'>Invalid address</div>}
            </div>
          )}
        />
        <Title name='SELECT 3 HEROES' required />
        <div className='md:flex md:gap-5 mb-3.5 md:mb-12'>
          <div
            className='w-full'
            style={{
              maxWidth: 639,
            }}
          >
            {isMobile ? (
              <Modal open={openChooseHero} onClose={() => setOpenChooseHero(false)} style={{ background: '#170A02E5' }}>
                <div className='px-4 pb-16 h-full'>
                  <div className='flex justify-end mb-1'>
                    <IconButton onClick={() => setOpenChooseHero(false)}>
                      <Close />
                    </IconButton>
                  </div>
                  <div className='flex flex-col gap-3 overflow-y-auto h-full p-4 bg-color-dark'>
                    <>
                      <div className='text-color-secondary text-xl font-skadi font-bold mb-8'>INVENTORY</div>
                      {filterSection}
                      {heroPool.map((item) => (
                        <div
                          key={item.tokenId}
                          className='cursor-pointer'
                          onClick={() => {
                            if (chosenHeros.length < 3) {
                              setChosenHeros([...chosenHeros, item]);
                              setOpenChooseHero(false);
                            }
                          }}
                        >
                          <CardHeroMini item={item} />
                        </div>
                      ))}
                      {notEnoughHeroSection}
                    </>
                  </div>
                </div>
              </Modal>
            ) : (
              <>
                <div
                  className='px-10 py-4 overflow-y-auto'
                  style={{
                    border: '1px solid #574239',
                    borderRadius: 10,
                    height: 628,
                    background: 'rgba(43, 23, 13, 0.8)',
                  }}
                >
                  <TitleSection text='INVENTORY' />
                  {filterSection}
                  <div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {heroPool.map((item) => (
                      <div
                        key={item.tokenId}
                        className='cursor-pointer'
                        onClick={() => {
                          if (chosenHeros.length < 3) {
                            setChosenHeros([...chosenHeros, item]);
                          }
                        }}
                      >
                        <CardHeroMini item={item} />
                      </div>
                    ))}
                  </div>
                </div>
                {notEnoughHeroSection}
              </>
            )}
          </div>
          {!isMobile && (
            <div className='flex items-center'>
              <img src={require(`assets/images/yellow-tripple-arrow.png`).default} className='w-5 h-5' />
            </div>
          )}
          <div className='flex-1' style={{ minWidth: 315 }}>
            <div
              className='p-4 flex flex-col gap-6'
              style={{
                border: '1px solid #574239',
                borderRadius: 10,
                background: 'rgba(43, 23, 13, 0.8)',
                height: 'fit-content',
              }}
            >
              <TitleSection text='DECK FOR SCHOLAR' />
              {[...Array(3)].map((_, index: number) => {
                return !!chosenHeros[index] ? (
                  <div key={index} className='relative'>
                    <CardHeroMini item={chosenHeros[index]} isMini />
                    <IconButton
                      style={{ background: '#745447' }}
                      className='w-7 h-7 absolute -top-2 -right-2'
                      onClick={() =>
                        setChosenHeros([...chosenHeros.filter((hero) => hero.tokenId !== chosenHeros[index].tokenId)])
                      }
                    >
                      <Clear style={{ fontSize: 18 }} />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    key={index}
                    style={{
                      background: '#432A1E',
                      borderRadius: 8,
                      height: 154,
                    }}
                    className='flex items-center justify-center'
                    onClick={isMobile ? () => setOpenChooseHero(true) : () => {}}
                  >
                    <img src={require(`assets/images/union.png`).default} />
                  </div>
                );
              })}
            </div>
            {isInvalidHeroes && (
              <div className='text-red-500 mt-2 md:mt-5 font-black text-center'>Select at least 3 heroes.</div>
            )}
          </div>
        </div>
        <div className='flex justify-center'>
          {isLoadingCreate || isLoadingEdit ? (
            <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'} className='md:w-96'>
              <CircularProgress color='inherit' className='w-5 h-5 mr-1.5' /> PROCESSING
            </DesignButton>
          ) : (
            <DesignButton
              fullWidth
              design='yellow'
              size={isMobile ? 'medium' : 'large'}
              className='md:w-96'
              onClick={() => {
                handleSubmit((values) => {
                  if (chosenHeros.length < 3) {
                    setIsInvalidHeroes(true);
                    return;
                  } else {
                    setIsInvalidHeroes(false);
                  }
                  const { scholarshipName, username, scholarWeb3Address, ratio } = values;
                  const params = {
                    scholarshipName: scholarshipName as string,
                    username: username as string,
                    scholarWeb3Address: scholarWeb3Address as string,
                    ratio: Number(ratio),
                    start: false,
                    heroTokenIds: chosenHeros.map((hero) => hero.tokenId),
                  };
                  if (isEdit) {
                    editScholarship({ scholarshipId: id, ...params });
                  } else {
                    createScholarship(params);
                  }
                })();
              }}
            >
              {`${isEdit ? 'EDIT' : 'CREATE'} SCHOLARSHIP`}
            </DesignButton>
          )}
        </div>
      </div>
      <Dialog maxWidth='xl' open={openCreateSubAcc}>
        <PopupCreateSubAcc
          onClose={() => setOpenCreateSubAcc(false)}
          setValue={(key: string, value: string) => {
            setValue(key, value);
          }}
        />
      </Dialog>
      <Dialog maxWidth='md' open={openPopupSuccess}>
        <div className='flex flex-col items-center py-5 md:p-14'>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
          <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>CONGRATULATION!</div>
          <div className='text-xs md:text-xl text-center mb-4 md:mb-10 px-5 md:px-0'>
            {`Your scholarship has been ${isEdit ? 'edited' : 'created'}.`}
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size='large'
            className='w-48 md:w-96'
            onClick={() => {
              const scholarWeb3Address = getValues('scholarWeb3Address');
              if (!!scholarWeb3Address) {
                navigate(scholarshipRoute.management.url());
              } else {
                navigate(`${scholarshipRoute.management.url()}?tab=manager&tab-manager=listing`);
              }
            }}
          >
            VIEW SCHOLARSHIP
          </DesignButton>
        </div>
      </Dialog>
      <Modal open={isLoadingCreate || isLoadingEdit} style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}>
        <>
          <PopupProcessing />
        </>
      </Modal>
    </div>
  );
};

export default CreateNew;
