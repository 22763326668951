import { Button, Modal, Pagination, PaginationItem, Tooltip } from '@mui/material';
import { Clear, InfoOutlined } from '@mui/icons-material';
import { CustomMUIDataTable, ExpansionCell, NoItem, tableHeadRender } from './TableAssigned';
import { memo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { scholarshipService } from 'services';
import { GetScholarshipsParams, ScholarshipsHeroType } from 'models/Scholarship';
import { CardHeroMinimized } from 'views/Home/cards';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { useSnackbar } from 'notistack';
import PopupProcessing from './PopupProcessing';
import { shorten } from 'utils/common';
import { useWindowDimensions } from 'hooks';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';
import { OKG_HERO } from 'env';

const handleRowHover = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-tableListing-' + rowIndex);
  if (!!row) row!.style.display = 'flex';
};

const handleRowHoverLeave = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-tableListing-' + rowIndex);
  if (!!row) row!.style.display = 'none';
};

const defaultSearch = {
  currentPage: 1,
  pageSize: 10,
  full: false,
  scholarshipWeb3AddressAssigned: false,
  isScholarshipActive: true,
};

const TableListing = () => {
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const [dataSearch, setDataSearch] = useState({ ...defaultSearch, web3Address: address?.toLocaleLowerCase() });

  const { data: scholarships, refetch } = useQuery(
    ['scholarshipService.getScholarshipManagerListing', dataSearch],
    ({ queryKey }) => scholarshipService.getScholarship(queryKey[1] as GetScholarshipsParams),
    { keepPreviousData: true },
  );

  const { mutate: cancelScholarship, isLoading } = useMutation(scholarshipService.cancelScholarship, {
    onSuccess: () => {
      refetch();
      enqueueSnackbar('Cancel successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Cancel failed!', { variant: 'error' });
    },
  });

  const HoverButton = memo(({ rowIndex }: { rowIndex: number }) => {
    const chosenScholarship = scholarships?.items[rowIndex];

    return (
      <div
        className='flex gap-2 h-full px-4 py-2 items-center justify-center'
        style={{
          background: isMobile ? '#271911' : '#382B24',
        }}
      >
        {/* <Button
          variant='contained'
          startIcon={<EditOutlined />}
          onClick={() => navigate(scholarshipRoute.editScholarship.url({ id: chosenScholarship?.scholarshipId }))}
        >
          Edit
        </Button> */}
        <Button
          variant='contained'
          startIcon={<Clear />}
          onClick={() => cancelScholarship({ scholarshipId: chosenScholarship?.scholarshipId ?? '' })}
        >
          Remove
        </Button>
      </div>
    );
  });

  const columns = [
    {
      name: 'scholarshipId',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const dataIndex = currentTableData[rowIndex].index;

          return (
            <>
              <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                <div>{shorten(value, 8, 0)}</div>
              </Tooltip>
              <div
                id={`hoverRow-tableListing-${dataIndex}`}
                className='absolute right-0'
                style={{
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  display: 'none',
                }}
              >
                <HoverButton rowIndex={dataIndex} />
              </div>
            </>
          );
        },
      },
    },
    {
      name: 'scholarshipName',
      label: 'Scholarship name',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
            <div>{shorten(value, 25, 0, 25)}</div>
          </Tooltip>
        ),
      },
    },
    {
      name: 'userName',
      label: 'Account',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
            <div>{shorten(value, 15, 0, 15)}</div>
          </Tooltip>
        ),
      },
    },
    {
      name: 'heroes',
      label: 'Heroes',
      options: {
        filter: true,
        sort: false,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
          <div className='flex gap-2'>
            {!!value &&
              value.map((hero: ScholarshipsHeroType, index: number) => (
                <div key={index}>
                  <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                    <CardHeroMinimized item={hero} />
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
    },
    {
      name: 'ratio',
      label: '',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
          tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
            <div className='flex gap-1 items-start'>
              Split Rate
              <Tooltip
                placement='top'
                title={
                  <div className='text-base text-color-secondary p-2'>Split Rate: % to scholar - % to manager</div>
                }
                componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
              >
                <InfoOutlined style={{ fontSize: 15 }} />
              </Tooltip>
            </div>
          )),
        customBodyRender: (value: any, { rowIndex }: any) => <div>{`${value}/${100 - value}`}</div>,
      },
    },
  ];

  const mobileColumns = [
    {
      name: 'scholarshipName',
      label: 'Scholarship',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          return (
            <>
              <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                <div>{shorten(value, 8, 0, 8)}</div>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: 'heroes',
      label: 'Heroes',
      options: {
        filter: true,
        sort: false,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
          <div className='flex gap-2'>
            {!!value &&
              value.map((hero: ScholarshipsHeroType, index: number) => (
                <div key={index}>
                  <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                    <CardHeroMinimized item={hero} />
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
    },
    {
      name: 'ratio',
      label: 'Split Rate',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => <div>{`${value}/${100 - value}`}</div>,
      },
    },
  ];

  return (
    <>
      {!!scholarships ? (
        scholarships.items.length === 0 ? (
          <NoItem />
        ) : (
          <>
            <div className='mb-3'>
              {isMobile ? (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={mobileColumns}
                  options={{
                    expandableRows: true,
                    selectableRows: undefined,
                    responsive: 'standard',
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    renderExpandableRow: (
                      rowData: string[],
                      rowMeta: {
                        dataIndex: number;
                        rowIndex: number;
                      },
                    ) => {
                      const expandScholarship = scholarships.items[rowMeta.dataIndex];

                      return (
                        <tr className='text-xs' style={{ background: '#1C110C' }}>
                          <td />
                          <td className='pr-12 py-2' colSpan={3}>
                            <div className='flex flex-col gap-2'>
                              <ExpansionCell
                                title='ID'
                                value={shorten(expandScholarship?.scholarshipId ?? '', 25, 0, 25)}
                              />
                              <ExpansionCell
                                title='Sub-account'
                                value={shorten(expandScholarship?.userName ?? '', 25, 0, 25)}
                                evenChild
                              />
                              <HoverButton rowIndex={rowMeta.dataIndex} />
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  }}
                />
              ) : (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={columns}
                  options={{
                    elevation: 0,
                    selectableRows: undefined,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    setRowProps: (row, dataIndex, rowIndex) => {
                      return {
                        className: 'relative',
                        onMouseEnter: () => handleRowHover(dataIndex),
                        onMouseLeave: () => handleRowHoverLeave(dataIndex),
                      };
                    },
                  }}
                />
              )}
            </div>
            <div className='flex justify-center'>
              <Pagination
                size='large'
                color='secondary'
                page={scholarships.paginationPage}
                count={scholarships.paginationCount}
                onChange={(event, page) => {
                  if (page > 0) setDataSearch({ ...dataSearch, currentPage: page });
                }}
                renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
              />
            </div>
            <Modal open={isLoading} style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}>
              <>
                <PopupProcessing />
              </>
            </Modal>
          </>
        )
      ) : null}
    </>
  );
};

export default TableListing;
