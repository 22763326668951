import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { queryClient, scholarshipService, userService } from 'services';
import { shorten } from 'utils/common';
import { InputField, Title } from './CreateNew';

const PopupCreateSubAcc = ({
  onClose,
  setValue,
}: {
  onClose: () => void;
  setValue: (key: string, value: string) => void;
}) => {
  const { isMobile } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useSelector(profileSelector);
  const { control, handleSubmit, getValues } = useForm({ mode: 'onChange' });

  const { data: info } = useQuery(
    ['userService.fetchUserInfo', { address }],
    ({ queryKey }) => {
      const { address } = queryKey[1] as any;
      return userService.fetchUserInfo(address ?? '');
    },
    {
      enabled: !!address,
    },
  );

  const { mutate: createSubAcc } = useMutation(scholarshipService.createSubAcc, {
    onSuccess: () => {
      queryClient.invalidateQueries('scholarshipService.getSubAccs');
      setValue('username', getValues('subAccName'));
      onClose();
      enqueueSnackbar('Create successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Create failed!', { variant: 'error' });
    },
  });

  return (
    <div className='md:px-8 py-2 md:py-4 text-color-secondary' style={{ width: isMobile ? 270 : 420 }}>
      <div className='text-2xl font-skadi text-center mb-4 md:mb-8'>Create Sub-account</div>
      <div className='flex justify-between items-end font-extrabold mb-2'>
        <div className='text-xs md:text-xl text-color-primary'>Main account</div>
        <div className='text-xs md:text-base text-color-legendary'>{info?.email}</div>
      </div>
      <div className='flex justify-between items-end font-extrabold mb-4 md:mb-8'>
        <div className='text-xs md:text-xl text-color-primary'>Wallet</div>
        <div className='text-xs md:text-base text-color-legendary'>{shorten(address!)}</div>
      </div>
      <Controller
        name='subAccName'
        defaultValue=''
        control={control}
        rules={{ required: 'This field is required.', minLength: 8, maxLength: 255 }}
        render={({ field, fieldState: { invalid, error } }) => {
          let mes = error?.message;
          if (error?.type === 'minLength' || error?.type === 'maxLength') {
            mes = 'Username must be must be between 8 and 255 characters.';
          }

          return (
            <div className='mb-6'>
              <Title name='USER NAME' required />
              <InputField
                {...field}
                fullWidth
                variant='outlined'
                size='medium'
                placeholder='antuk526'
                error={invalid}
              />
              {invalid && <div className='text-red-500 text-tiny md:text-base mt-1 font-black'>{mes}</div>}
            </div>
          );
        }}
      />
      <DesignButton
        fullWidth
        design='yellow'
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          handleSubmit((values) => createSubAcc({ subAccName: values.subAccName }))();
        }}
      >
        Create
      </DesignButton>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupCreateSubAcc;
