import {
  GetBalanceByToken,
  WithDrawalTokenParams,
  DepositResType,
  DepositTokenParams,
  GetWithDrawalHistoryParams,
  WithDrawalHistoryResType,
} from 'models/Withdrawal';
import { client } from './axios';
import { TokenEnum } from './helper';

const getBalance = ({ token }: GetBalanceByToken): Promise<number> =>
  client.get(`/api/withdrawal-deposit/${token}/balance`);
const withdrawal = ({ token, amount }: WithDrawalTokenParams) =>
  client.post(`/api/withdrawal-deposit/${token}/withdrawal`, { amount });
const deposit = ({ token, amount }: DepositTokenParams): Promise<DepositResType> =>
  client.post(`/api/withdrawal-deposit/${token}/deposit`, { amount });
const confirmWithdrawalDeposit = (id: string, token: TokenEnum, transactionHash?: string) =>
  client.put(`/api/withdrawal-deposit/${token}/deposit/${id}`, { transactionHash }).catch((err: any) => {
    const data = err?.response?.data;
    if (data?.code === 500 && data?.message?.toLowerCase() === 'Status is not valid'.toLowerCase()) {
      console.log('this isnt error', data);
    } else {
      throw err;
    }
  });
const deleteDeposit = (id: string, token: TokenEnum) => client.delete(`/api/withdrawal-deposit/${token}/deposit/${id}`);

const getWithdrawalHistories = (params?: GetWithDrawalHistoryParams): Promise<WithDrawalHistoryResType> =>
  client.get(`/api/withdrawal-deposit/histories`, { params });

export default {
  getBalance,
  withdrawal,
  confirmWithdrawalDeposit,
  deposit,
  getWithdrawalHistories,
  deleteDeposit,
};
