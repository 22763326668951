import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';

export type ProfileState = {
  address?: string;
  isLoggedIn?: boolean;
  token?: string;
  registered?: boolean;
  tokens?: {
    OKG: string;
    KAB: string;
  };
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    signIn: (state, { payload: profile }) => {
      localStorage.setItem('profile', JSON.stringify(profile));
      return { ...state, ...profile, isLoggedIn: true };
    },
    signOut: () => {
      localStorage.removeItem('profile');
      return { isLoggedIn: false };
    },
    updateInfo: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { signIn, signOut, updateInfo } = profileSlice.actions;

export const profileSelector = ({ profile }: RootState) => profile;

export default profileSlice.reducer;
