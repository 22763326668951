import { AppBar, Container, Divider, IconButton, Link, Toolbar } from '@mui/material';
import { useWindowDimensions } from 'hooks';

const Footer = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <AppBar component='footer' position='static' style={{ background: '#000' }}>
      <Toolbar
        component={Container}
        className='flex flex-col-reverse md:flex-row gap-5 md:gap-0 text-sm md:justify-between py-6 md:py-0 border-color-brown'
        style={{ color: '#CCC3B5', borderTop: isMobile ? '1px solid' : '' }}
      >
        <div className='flex flex-col md:flex-row gap-6 md:gap-4 w-full md:w-auto text-center md:text-left'>
          <div>© 2021 CROS-All Rights Reserved</div>
          <Divider
            orientation={isMobile ? 'horizontal' : 'vertical'}
            variant='fullWidth'
            flexItem
            className='border-color-brown'
          />
          <div>Contact Us: support@ookeenga.io</div>
        </div>
        <div className='flex gap-3.5'>
          {[
            { icon: 'Telegram', url: 'https://t.me/ookeenga_global' },
            { icon: 'Discord', url: 'https://dsc.gg/ookeenga' },
            { icon: 'Twitter', url: 'https://twitter.com/ookeenga' },
            { icon: 'Medium', url: 'https://ookeenga.medium.com/' },
            { icon: 'Facebook', url: 'https://www.facebook.com/ookeenga.official' },
            { icon: 'Youtube', url: 'https://www.youtube.com/channel/UCqQpViSkkAq_aFP3hj3GL8w' },
          ].map((item, index) => (
            <div key={index} className='flex flex-col items-center'>
              <Link href={item.url} target='_blank'>
                <IconButton className=''>
                  <img src={require(`assets/socials/Footer-${item.icon}.png`).default} className='w-6' />
                </IconButton>
              </Link>
            </div>
          ))}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
