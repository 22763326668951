import { Button, Modal, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { TokenEnum } from 'services/helper';
import PopupProcessing from 'views/Scholarship/components/PopupProcessing';

const AmountField = styled(TextField)`
  border-radius: 8px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 8px;
  }
  & .MuiOutlinedInput-input {
    font-family: 'Avenir';
    font-weight: 800;
    font-size: 24px;
    color: #392609;
    padding: 8px 16px;
    @media (max-width: 800px) {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

type PopupDepositProps = {
  isLoading: boolean;
  isSuccess: boolean;
  gameToken: TokenEnum;
  maxAmount: number | undefined;
  onConfirm: (amount: number, token: TokenEnum) => void;
  onClose: () => void;
};

const PopupDeposit: React.FC<PopupDepositProps> = ({
  isLoading,
  isSuccess,
  gameToken,
  maxAmount,
  onConfirm,
  onClose,
}) => {
  const { isMobile } = useWindowDimensions();
  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });
  const upperCaseToken = gameToken?.toUpperCase();
  // const maxLimit = useMemo(() => {
  //   return Number(
  //     customizeFloatNumber(Number((tokenOnPopup === TokenEnum.KAB ? tokens?.KAB : tokens?.OKG) ?? '0') / 1e18),
  //   );
  // }, [tokenOnPopup, tokens?.KAB, tokens?.OKG]);

  return (
    <div className='px-2 md:px-8 py-4 md:py-10 text-color-secondary' style={{ maxWidth: 520 }}>
      {isSuccess ? (
        <div className='flex flex-col items-center'>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
          <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>SUCCESS!</div>
          <div className='text-xs md:text-xl text-center mb-4 md:mb-8 px-5 md:px-0'>
            Your transaction has been completed.
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size='large'
            imageSize='small'
            className='w-48'
            onClick={() => {
              onClose();
            }}
          >
            DONE
          </DesignButton>
        </div>
      ) : (
        <>
          <div className='font-skadi text-3xl mb-4 md:mb-8 text-center'>Deposit</div>
          <div className='font-skadi mb-4 md:mb-8'>
            Exchange {upperCaseToken} token to ingame g{upperCaseToken}
          </div>
          <Controller
            name='amount'
            defaultValue=''
            control={control}
            rules={{
              required: true,
              pattern: /^\d*\.?\d*$/,
              min: 1,
              max: maxAmount,
            }}
            render={({ field, fieldState: { invalid, error } }) => {
              let mes = `The minimum deposit amount is 1 ${upperCaseToken}`;
              let color = '#EF4444';
              if (error?.type === 'max') {
                mes = `Your maximum amount is ${maxAmount?.toLocaleString()} ${upperCaseToken}`;
              }

              return (
                <div className='mb-4 md:mb-8 text-color-primary'>
                  <div className='text-sm md:text-base font-black mb-2 md:mb-1 flex justify-between gap-2 break-all'>
                    <div className='flex-none'>
                      AMOUNT <span style={{ color: '#FF613F' }}>*</span>
                    </div>
                    <div>{`AVAILABLE: ${maxAmount?.toLocaleString()} ${upperCaseToken}`}</div>
                  </div>
                  <AmountField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='0'
                    size='medium'
                    error={invalid}
                    InputProps={{
                      endAdornment: (
                        <div
                          className='flex gap-2 md:pr-5 md:text-xl font-avenir font-bold items-center'
                          style={{ color: '#392609' }}
                        >
                          {maxAmount && (
                            <Button
                              variant='contained'
                              className='font-bold text-color-primary text-xs md:text-sm'
                              style={{ background: '#86632D', borderRadius: 8 }}
                              onClick={() => setValue('amount', Math.floor(maxAmount), { shouldValidate: true })}
                            >
                              Max
                            </Button>
                          )}

                          {upperCaseToken}
                        </div>
                      ),
                      type: 'number',
                      onKeyDown: (el: any) => {
                        if (
                          el.which === 189 ||
                          el.which === 190 ||
                          el.which === 109 ||
                          el.which === 110 ||
                          el.which === 107 ||
                          el.which === 187 ||
                          el.which === 69 ||
                          el.which === 231
                        )
                          el.preventDefault();
                      },
                    }}
                  />
                  {invalid && (
                    <div className='text-tiny md:text-sm mt-1' style={{ color }}>
                      {mes}
                    </div>
                  )}
                </div>
              );
            }}
          />
          <DesignButton
            fullWidth
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            onClick={handleSubmit((values: any) => {
              onConfirm(Number(values?.amount) ?? 0, gameToken);
            })}
          >
            DEPOSIT
          </DesignButton>
        </>
      )}
      <CloseButton onClick={onClose} />
      <Modal open={isLoading} style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}>
        <>
          <PopupProcessing />
        </>
      </Modal>
    </div>
  );
};

export default PopupDeposit;
