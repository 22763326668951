import { createRoot } from 'react-dom/client';

import App from 'App';

// import 'tailwindcss/tailwind.css';
import './input.css';
import 'App.scss';

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(<App />);