import { getNewRankColor, modifyRank } from 'utils/common';

const RankColor = ({ text, rank }: { text: string | JSX.Element; rank: string }) => {
  const color = getNewRankColor(modifyRank(rank));

  return (
    <div style={{ background: color }} className='bg-clip-text text-transparent'>
      {text}
    </div>
  );
};

export default RankColor;
