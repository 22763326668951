import { CardMedia, CircularProgress, Divider, Link } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { web3, OKGMarketContract, erc20Contract } from 'contracts';
import { PopupController } from 'models/Common';
import { CocoonType } from 'models/Cocoon';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { nftService } from 'services';
import { WarningAmberOutlined } from '@mui/icons-material';
import { OKG_MARKET, OKG_TOKEN_ADDRESS } from 'env';
import { shorten } from 'utils/common';
import { useWindowDimensions } from 'hooks';

type PopupProps = PopupController & {
  item: CocoonType;
};

const PopupBuyCocoon = ({ item, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);

  const { data: isEnough } = useQuery(
    ['fetchBalance', { address, OKG_TOKEN_ADDRESS, ...item.sale }],
    async ({ queryKey }) => {
      const { address, price, OKG_TOKEN_ADDRESS } = queryKey[1] as any;
      const balance = await erc20Contract(OKG_TOKEN_ADDRESS!).methods.balanceOf(address).call();
      return balance >= price * 10 ** 18;
    },
    { placeholderData: true, initialData: true },
  );

  const {
    mutate: buy,
    isLoading,
    isSuccess,
  } = useMutation(
    async () => {
      if (!!item?.sale) {
        const confirm = await nftService.buyCocoon({ saleId: item?.sale?._id });
        const allowance = await erc20Contract(OKG_TOKEN_ADDRESS!).methods.allowance(address, OKG_MARKET).call();
        if (Number(allowance) < Number(confirm.price)) {
          await erc20Contract(OKG_TOKEN_ADDRESS!).methods.approve(OKG_MARKET, web3.utils.toTwosComplement(-1)).send({ from: address });
        }
        const purchaseData = {
          sale: {
            saleId: confirm.iSaleId,
            nft: confirm.contract,
            tokenId: confirm.tokenId,
            paymentToken: confirm.currency,
            price: confirm.price,
            seller: confirm.seller,
            feeRate: confirm.feeRate,
          },
          signature: confirm.signature,
        };

        await OKGMarketContract(OKG_MARKET!)
          .methods.matchSale(...Object.values(purchaseData))
          .send({
            from: address,
          });
      }
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Buy cocoon successfully!', { variant: 'success' });
        if (!!onSuccess) {
          onSuccess();
        }
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Buy cocoon failed!', { variant: 'error' });
      },
    },
  );

  return (
    <div className='flex flex-col items-center text-color-secondary py-7 md:p-14' style={{ maxWidth: 988 }}>
      {isSuccess ? (
        <>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 h-24 md:h-auto' />
          <span className='text-xl md:text-3xl font-skadi font-normal md:mb-3'>PAYMENT SUCCEEDED</span>
          <div className='text-xs md:text-xl text-center mb-4 md:mb-10 px-10 md:px-0'>
            This awesome item is now yours!
          </div>
          <div className='w-48 md:w-96'>
            <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'} onClick={onClose}>
              VIEW ITEM
            </DesignButton>
          </div>
        </>
      ) : (
        <>
          <span className='text-xl md:text-3xl font-skadi font-normal mb-2 md:mb-6'>PURCHASING ITEM</span>
          <div
            className='flex flex-col md:flex-row gap-2 md:gap-16 w-full bg-color-dark mb-6'
            style={{ borderRadius: 10, padding: isMobile ? 12 : 30 }}
          >
            <CardMedia
              component='video'
              src={item.animationUri}
              style={{ width: isMobile ? 236 : 352, height: isMobile ? 236 : 352, borderRadius: 10 }}
              loop
              autoPlay
              muted
              controls={false}
              playsInline
            />
            <div className='flex flex-col justify-between text-xs md:text-xl'>
              <div className='flex flex-col gap-1 md:gap-3 break-all mb-6 md:mb-0'>
                <div className='md:text-2xl font-bold'>{item.name}</div>
                <Divider className='w-full md:w-96' />
                <div>ID: {item.tokenId}</div>
                <div>Owner: {shorten(item.owner)}</div>
              </div>
              <div>
                <div className='mb-1 md:mb-2'>Total amount</div>
                <Divider className='w-full md:w-96 mb-1 md:mb-4' />
                <div className='flex justify-between'>
                  <div>{item.sale?.price}</div>
                  <div className='flex gap-1 items-center'>
                    <img src={require('assets/icons/OKG-token.png').default} className='h-5' /> OKG
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-48 md:w-96'>
            {isLoading ? (
              <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'}>
                <CircularProgress color='inherit' className='w-5 h-5 mr-1.5' /> PROCESSING
              </DesignButton>
            ) : (
              <>
                <DesignButton
                  fullWidth
                  design='yellow'
                  size={isMobile ? 'medium' : 'large'}
                  onClick={() => {
                    if (isEnough) {
                      buy();
                    }
                  }}
                >
                  CHECK OUT
                </DesignButton>
                {!isEnough && (
                  <div className='text-color-primary text-center text-sm mt-3'>
                    <WarningAmberOutlined className='pb-1.5' style={{ color: '#DA5B00' }} /> Balance insufficient.{' '}
                    <Link href='#' className='text-color-secondary' style={{ textDecorationColor: '#F1E9DC' }}>
                      Deposit now
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {!isLoading && <CloseButton onClick={onClose} />}
    </div>
  );
};

export default PopupBuyCocoon;
