import { Container } from '@mui/material';
import { useTabs } from 'hooks';
import Manager from './Manager';
import Scholar from './Scholar';

const tabs = [
  {
    code: 'manager',
    label: `Manager`,
    icon: (selected?: boolean) => (
      <i
        className={`w-9 h-8 bg-color-${selected ? 'secondary' : 'primary'}`}
        style={{
          WebkitMask: `url(${require(`assets/icons/icon-manager.svg`).default}) no-repeat top center / cover`,
        }}
      />
    ),
    component: <Manager />,
  },
  {
    code: 'scholar',
    label: `Scholar`,
    icon: (selected?: boolean) => (
      <i
        className={`w-7 h-8 bg-color-${selected ? 'secondary' : 'primary'}`}
        style={{
          WebkitMask: `url(${require(`assets/icons/icon-scholar.svg`).default}) no-repeat top center / cover`,
        }}
      />
    ),
    component: <Scholar />,
  },
];

const Management = () => {
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <div className='min-h-screen py-10 md:py-16 text-white'>
      <div
        className='py-20'
        style={{
          background: `url(${require('assets/images/scholarship-header.png').default}) no-repeat top center / cover`,
          fontSize: 74,
        }}
      >
        <div className='hidden md:block font-skadi text-center'>SCHOLARSHIP</div>
      </div>
      <Container>
        <div className='flex justify-center'>
          <div
            className='bg-color-browny flex w-full md:w-fit md:text-2xl font-skadi gap-5 px-10 -mt-10'
            style={{ borderRadius: '10px' }}
          >
            {tabs.map((tab, index) => {
              const selected = activeTab === tab.code;

              return (
                <div key={index}>
                  <div
                    className={`flex gap-3 items-center text-color-${
                      selected ? 'secondary' : 'primary'
                    } cursor-pointer px-3 md:px-6 py-5`}
                    onClick={(event) => onChangeTab(event, tab.code)}
                  >
                    {tab.icon(selected)}
                    {tab.label}
                  </div>
                  <div className='h-1 w-full' style={{ backgroundColor: selected ? '#F07300' : 'transparent' }} />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      {tabs.map((tab) => (
        <div hidden={tab.code !== activeTab} key={tab.code}>
          {tab.component}
        </div>
      ))}
    </div>
  );
};

export default Management;
