import { OKGSwapContract } from 'contracts';
import { OKG_TOKEN_ADDRESS, OKG_SWAP } from 'env';
import { TokenEnum } from 'services/helper';
import { depositToken } from './depositToken';

export const depositOkg = async (address: string | undefined, amount: number) => {
  const tokenAddress = OKG_TOKEN_ADDRESS || '';

  const token = TokenEnum.OKG;
  const swapContractAddress = OKG_SWAP || '';
  const swapContract = OKGSwapContract;
  return depositToken(address, amount, tokenAddress, token, swapContractAddress, swapContract);
};
