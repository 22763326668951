import { Box, Divider, Paper, styled, Tooltip } from '@mui/material';
import { BorderRankColor, RankColor } from 'components';
import { useWindowDimensions } from 'hooks';
import { useMemo } from 'react';
import { getRankColor } from 'utils/common';

const CustomPaper = styled(Paper)`
  background-image: none;
  background-color: transparent;
  border-radius: 8px;
  :hover {
    box-shadow: 0px 0px 16px #da8b14;
  }
`;

const CustomBox = styled(Box)`
  background: #432A1E;
  border: 1px solid #574239;
  border-radius: 8px;
    :hover {
      0px 4px 12px rgba(249, 149, 0, 0.4)
      border-radius: 8px;
    }
`;

const IconTag = ({ value, isRace }: { value: string; isRace?: boolean }) => (
  <div className='rounded-full p-0.5' style={{ background: '#170A02', border: '1px solid #725B35' }}>
    <img src={require(`assets/games/${isRace ? 'Race' : 'Class'}_${value}.png`).default} className='h-5 w-5' />
  </div>
);

const CardHeroMini = ({ item, isMini }: { item: any; isMini?: boolean }) => {
  const { isMobile } = useWindowDimensions();
  const newIsMini = useMemo(() => isMini || isMobile, [isMini, isMobile]);

  const data = useMemo(() => {
    return {
      name: item.name,
      level: item.externalAttrs?.level ?? 0,
      power: item.externalAttrs?.power ?? 0,
      image: item.image,
      race: item.race ?? item.externalAttrs?.race ?? 'None',
      class: item.class ?? item.externalAttrs?.class ?? 'None',
      rank: item.rank ?? item.externalAttrs?.rank ?? '',
      skills: item.externalAttrs?.skills ?? [],
      troops: item.externalAttrs?.troops ?? [],
      breedCount: item.externalAttrs?.breedCount ?? 0,
      currentStats: {
        hp: item.currentStats?.hp ?? item.externalAttrs?.currentStats.hp ?? 0,
        atk: item.currentStats?.atk ?? item.externalAttrs?.currentStats.atk ?? 0,
        attackSpeed: item.currentStats?.attackSpeed ?? item.externalAttrs?.currentStats.attackSpeed ?? 0,
        dps: item.currentStats?.dps ?? item.externalAttrs?.currentStats.dps ?? 0,
      },
    };
  }, [
    item.class,
    item.currentStats?.atk,
    item.currentStats?.attackSpeed,
    item.currentStats?.dps,
    item.currentStats?.hp,
    item.externalAttrs?.breedCount,
    item.externalAttrs?.class,
    item.externalAttrs?.currentStats.atk,
    item.externalAttrs?.currentStats.attackSpeed,
    item.externalAttrs?.currentStats.dps,
    item.externalAttrs?.currentStats.hp,
    item.externalAttrs?.level,
    item.externalAttrs?.power,
    item.externalAttrs?.race,
    item.externalAttrs?.rank,
    item.externalAttrs?.skills,
    item.externalAttrs?.troops,
    item.image,
    item.name,
    item.race,
    item.rank,
  ]);

  const statsSection = useMemo(
    () => (
      <div
        className='grid grid-cols-2 gap-x-5 gap-y-2'
        style={{ fontSize: newIsMini ? 13 : 16, marginBottom: newIsMini ? 0 : 20 }}
      >
        {[
          { icon: 'POW', value: Math.round(data?.power ?? 0) },
          { icon: 'HP', value: Math.round(data.currentStats.hp ?? 0) },
          { icon: 'ATK', value: (data.currentStats.atk ?? 0).toFixed(1) },
          { icon: 'SPD', value: (data.currentStats.attackSpeed ?? 0).toFixed(1) },
          { icon: 'DPS', value: Math.round(data.currentStats.dps ?? 0) },
          { icon: 'BRE', value: Math.round(data?.breedCount ?? 0) },
        ].map((item, index) => (
          <div key={index}>
            <div className='flex gap-1'>
              <img
                src={require(`assets/games/Stat_${item.icon}.png`).default}
                style={{ width: newIsMini ? 16 : 20, height: newIsMini ? 16 : 20 }}
              />
              <span className='text-color-secondary'>{item.value}</span>
            </div>
          </div>
        ))}
      </div>
    ),
    [
      data?.breedCount,
      data.currentStats.atk,
      data.currentStats.attackSpeed,
      data.currentStats.dps,
      data.currentStats.hp,
      data?.power,
      newIsMini,
    ],
  );

  const avatarSection = useMemo(
    () => (
      <div className='flex-none'>
        <BorderRankColor rank={data.rank} borderRadius={8} borderWidth={2}>
          <div
            className='w-full'
            style={{ background: 'linear-gradient(180deg, #170A02 75.66%, #372215 100%)', borderRadius: 'inherit' }}
          >
            <div className='relative'>
              <img
                src={data.image}
                className='rounded-lg'
                style={{ height: newIsMini ? 110 : 116, width: newIsMini ? 110 : '100%' }}
              />
              <div
                className='absolute top-0 font-skadi font-bold text-xs text-white py-1 pl-2 pr-4'
                style={{
                  background: `url(${require(`assets/images/trapezoid-tag.png`).default}) no-repeat center/100% 100%`,
                }}
              >
                {['Lv.', data.level].join(' ')}
              </div>
              <div className='absolute top-0 right-0 flex flex-col gap-0.5'>
                <IconTag value={data.race} isRace />
                <IconTag value={data.class} />
              </div>
            </div>
            {!newIsMini && (
              <div className='flex items-center justify-center gap-1 text-sm font-black py-2'>
                <img src={require(`assets/icons/icon-power.png`).default} style={{ width: 13, height: 12 }} />
                {data.power}
              </div>
            )}
          </div>
        </BorderRankColor>
      </div>
    ),
    [data.class, data.image, data.level, data.power, data.race, data.rank, newIsMini],
  );

  return newIsMini ? (
    <CustomBox className='flex gap-6 p-4'>
      {avatarSection}
      <div className='text-color-secondary w-full'>
        <div className='font-extrabold mb-5'>
          <RankColor text={data.name} rank={data?.rank ?? 'warrior'} />
        </div>
        {statsSection}
      </div>
    </CustomBox>
  ) : (
    <Tooltip
      disableInteractive={true}
      componentsProps={{
        tooltip: { className: 'bg-color-dark', style: { border: '1px solid #574239', backdropFilter: 'blur(10px)' } },
      }}
      arrow={false}
      title={
        <div className='text-base text-color-secondary p-5'>
          <div className='flex justify-between mb-3'>
            <div className='text-xl font-black'>{data.name}</div>
            <div className='flex gap-1'>
              <img src={require(`assets/games/Race_${data.race ?? 'None'}.png`).default} className='h-6 w-6' />
              <Divider orientation='vertical' variant='middle' flexItem style={{ border: '0.1px solid #574239' }} />
              <img src={require(`assets/games/Class_${data.class ?? 'None'}.png`).default} className='h-6 w-6' />
            </div>
          </div>
          <div className='flex justify-between text-xs font-extrabold mb-5'>
            <div className={`tracking-extra text-color-${getRankColor((data.rank ?? '').toLowerCase())}`}>
              {data.rank}
            </div>
            <div className='text-color-primary'>{`Lvl. ${data.level}`}</div>
          </div>
          <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
          {statsSection}
          <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
          <div className='mb-5'>
            <div className='font-skadi'>Skills</div>
            <div className='flex gap-2'>
              {!!data?.skills &&
                Object.values(data.skills).map((value: any, index) => (
                  <img key={index} src={value?.icon} className='h-20 w-16' />
                ))}
            </div>
          </div>
          <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
          <div>
            <div className='font-skadi'>Troops</div>
            <div className='flex gap-2'>
              {!!data?.troops &&
                Object.values(data?.troops).map((value: any, index) => (
                  <img key={index} src={value?.icon} className='h-20 w-16' />
                ))}
            </div>
          </div>
        </div>
      }
      placement='right'
    >
      <CustomPaper elevation={4}>{avatarSection}</CustomPaper>
    </Tooltip>
  );
};

export default CardHeroMini;
