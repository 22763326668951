import { CardMedia, Divider, Paper } from '@mui/material';
import { HeroType } from 'models/Hero';
import { useWindowDimensions } from 'hooks';
import { currencyFormat, modifyRank } from 'utils/common';
import { BorderRankColor, RankColor } from 'components';
import { memo } from 'react';

const CardHero = ({ item, notDisplaySale }: { item: HeroType; notDisplaySale?: boolean }) => {
  const { isMobile } = useWindowDimensions();
  const isSale = !!item?.sale;
  const isLocked = item.isLocked;
  const isStandardHero = !!item?.externalAttrs?.lineage && item?.externalAttrs?.lineage.parents.length > 0;
  const rank = modifyRank(item?.externalAttrs?.rank ?? 'warrior');

  const Stats = memo(() => (
    <div className='md:mb-1 md:px-5 grid grid-cols-3 gap-2 md:gap-3'>
      {[
        { icon: 'POW', value: Math.round(item.externalAttrs?.power ?? 0) },
        { icon: 'HP', value: Math.round(item.externalAttrs?.currentStats.hp ?? 0) },
        { icon: 'ATK', value: (item.externalAttrs?.currentStats.atk ?? 0).toFixed(1) },
        { icon: 'SPD', value: (item.externalAttrs?.currentStats.attackSpeed ?? 0).toFixed(1) },
        { icon: 'DPS', value: Math.round(item.externalAttrs?.currentStats.dps ?? 0) },
        { icon: 'BRE', value: Math.round(item.externalAttrs?.breedCount ?? 0) },
      ].map((item, index) => (
        <div key={index} className='flex items-center gap-1'>
          <img src={require(`assets/games/Stat_${item.icon}.png`).default} className='w-4 md:w-6 h-4 md:h-6' />
          <span className='text-color-secondary text-xs md:text-base'>{item.value}</span>
        </div>
      ))}
    </div>
  ));

  const PriceBox = memo(() => (
    <div className='flex md:justify-center items-center'>
      {isSale ? (
        <div className='flex items-center md:justify-center gap-1 md:gap-2 md:text-2xl text-color-secondary break-all font-bold px-1 w-full'>
          <img src={require('assets/icons/OKG-token.png').default} className='h-4 md:h-6' />
          {currencyFormat(Number(item?.sale?.price ?? '0'))}
        </div>
      ) : (
        <div className='text-color-primary text-xs md:text-base font-bold md:my-1 text-center'>{`${
          isLocked ? 'On scholarship' : 'Not listed'
        }`}</div>
      )}
    </div>
  ));

  return (
    <>
      {isMobile ? (
        <div className='bg-color-dark w-full p-2' style={{ border: '0.5px solid #574239', borderRadius: 5 }}>
          <div className='flex flex-1 gap-5'>
            <BorderRankColor borderRadius={10} borderWidth={1} rank={rank}>
              <CardMedia
                image={item.image}
                className='max-w-full'
                style={{ borderRadius: 10, width: 88, height: 88 }}
              >
                <div className='flex flex-col justify-between h-full'>
                  <div className='p-0.5 flex justify-between'>
                    <div>
                      <div
                        className='py-0.5 px-1 text-tiny bg-color-dark text-white'
                        style={{ borderRadius: 3, height: '' }}
                      >{`Lv. ${item.externalAttrs?.level}`}</div>
                    </div>
                    <div className='flex flex-col gap-0.5'>
                      <div className='bg-color-dark rounded-full h-6 w-6 flex items-center justify-center'>
                        <img
                          src={require(`assets/games/Race_${item.externalAttrs?.race ?? 'None'}.png`).default}
                          style={{ height: 18, width: 18 }}
                        />
                      </div>
                      <div className='bg-color-dark rounded-full h-6 w-6 flex items-center justify-center'>
                        <img
                          src={require(`assets/games/Class_${item.externalAttrs?.class ?? 'None'}.png`).default}
                          style={{ height: 18, width: 18 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='bg-color-dark uppercase text-center py-0.5 font-black'
                    style={{ fontSize: 8, lineHeight: '9px', borderRadius: '0px 0px 10px 10px' }}
                  >
                    <RankColor
                      text={
                        <>
                          <div>{isStandardHero ? '' : 'Genesis'}</div>
                          <div>{item.externalAttrs?.rank}</div>
                        </>
                      }
                      rank={item?.externalAttrs?.rank ?? 'warrior'}
                    />
                  </div>
                </div>
              </CardMedia>
            </BorderRankColor>
            <div className='flex-1 flex flex-col gap-3'>
              <div className='font-bold text-sm text-color-secondary break-all'>{item.name ?? '-'}</div>
              <Stats />
              {!notDisplaySale && (
                <>
                  <Divider className='w-full border-color-stroke-black' style={{ borderBottomWidth: '0.5px' }} />
                  <PriceBox />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Paper className='card-hover w-full rounded-t-xl pt-0.5 bg-transparent' elevation={4}>
          <div
            style={{
              background: `url(${require(`assets/images/frame-card-${rank}.png`).default})`,
            }}
            className='absolute inset-0 card-background'
          />
          <CardMedia image={item.image} className='h-60 flex flex-col justify-between rounded-t-xl pt-0.5'>
            <div
              className='bg-color-dark text-center pt-3 pb-1.5 px-4 grid grid-cols-4 items-center'
              style={{ fontSize: 13 }}
            >
              <div
                style={{ borderRight: '1px solid rgba(255, 255, 255, 0.5)' }}
              >{`Lv. ${item.externalAttrs?.level}`}</div>
              <div className='col-span-2 uppercase font-bold' style={{ lineHeight: '18px' }}>
                <RankColor
                  text={
                    <>
                      <div>{isStandardHero ? '' : 'Genesis'}</div>
                      <div>{item.externalAttrs?.rank}</div>
                    </>
                  }
                  rank={item?.externalAttrs?.rank ?? 'warrior'}
                />
              </div>
              <div className='flex gap-1 justify-center' style={{ borderLeft: '1px solid rgba(255, 255, 255, 0.5)' }}>
                <img
                  src={require(`assets/games/Race_${item.externalAttrs?.race ?? 'None'}.png`).default}
                  className='h-6'
                />
                <img
                  src={require(`assets/games/Class_${item.externalAttrs?.class ?? 'None'}.png`).default}
                  className='h-6'
                />
              </div>
            </div>
            <div className={'bg-color-dark text-center w-full py-1 font-bold'}>{item.name}</div>
          </CardMedia>
          <div className='pt-2 pb-3.5 bg-color-greyish'>
            <Stats />
            {!notDisplaySale && (
              <>
                <Divider className='w-full border-color-stroke-black border-b-2 mb-1' />
                <PriceBox />
              </>
            )}
          </div>
        </Paper>
      )}
    </>
  );
};

export default CardHero;
