import { CreateNew, Management } from 'views/Scholarship/components';

type RouteType = {
  path: string;
  url: (query?: any) => string;
  element: JSX.Element;
  auth: boolean;
  name: string;
};

type ScholarshipRouteType = {
  [key: string]: RouteType;
};

export const scholarshipRoute: ScholarshipRouteType = {
  management: {
    path: '/management',
    url: () => '/scholarship/management',
    element: <Management />,
    auth: false,
    name: 'Create New Scholarship',
  },
  createNew: {
    path: '/create-new',
    url: () => '/scholarship/create-new',
    element: <CreateNew />,
    auth: false,
    name: 'Create New Scholarship',
  },
  // editScholarship: {
  //   path: '/edit/:id',
  //   url: ({ id }) => `/scholarship/edit/${id}`,
  //   element: <CreateNew />,
  //   auth: false,
  //   name: 'Create New Scholarship',
  // },
};
