import axios from 'axios';
import {
  UserStatisticType,
  UserHistoriesType,
  UserEmailType,
  UserInfoType,
  UserHistoriesParams,
  UserOverviewType,
} from 'models/User';
import { client } from './axios';

const fetchUserStatistic = (address: string): Promise<UserStatisticType> =>
  client.get(`/api/users/${address}/statistic`);
const fetchUserHistories = ({ address, ...params }: UserHistoriesParams): Promise<UserHistoriesType> =>
  client.get(`/api/histories/${address}`, { params });
const fetchUserInfo = (address: string): Promise<UserInfoType> => client.get(`/api/users/${address}`);
const subcribeByEmail = (body: UserEmailType) =>
  axios.post(`https://dev-mkp-game-hub.ookeenga.io/api/Email/subscribe`, body);
const fetchUserOverview = (): Promise<UserOverviewType> => client.get(`/api/scholars/scholarships/assets/overview`);

export default {
  fetchUserStatistic,
  fetchUserHistories,
  fetchUserInfo,
  subcribeByEmail,
  fetchUserOverview,
};
