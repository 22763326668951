import React from 'react';
import { Container, Dialog, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { profileRoute } from 'routes';
import { ProfileNav, PopupGuideLine } from 'views/Profile/components';
import { parse } from 'query-string';
import { useIsAccountOwner, useWindowDimensions } from 'hooks';
import { NotLoggedInSection } from 'components';

const Profile = () => {
  const location = useLocation();
  const { isMobile } = useWindowDimensions();
  const [isAccountOwner] = useIsAccountOwner();
  const { owner } = parse(location.search);
  const { isLoggedIn } = useSelector(profileSelector);
  const [open, setOpen] = React.useState(location.state === 'home');

  return (
    <>
      {isMobile && <ProfileNav />}
      <div
        className='min-h-screen md:py-20'
        style={{
          ...(!isMobile
            ? {
                background: `url(${
                  require('assets/images/background-profile.png').default
                }) no-repeat top center / contain`,
                backgroundSize: '100%',
              }
            : {}),
        }}
      >
        <Container className='flex flex-col md:flex-row items-center md:items-start md:gap-20 pt-4 md:pt-8'>
          {!isMobile ? <ProfileNav /> : <Divider className='mb-1.5 border-color-brown w-full' />}
          <div className='flex-1'>
            {isLoggedIn || !!owner ? (
              <Routes>
                {Object.values(profileRoute).map(
                  ({ path, element, auth }, index) =>
                    ((auth && isAccountOwner) || !auth) && <Route key={index} path={path} element={element} />,
                )}
                <Route path='*' element={<Navigate to={profileRoute.inventory.url} />} />
              </Routes>
            ) : (
              <NotLoggedInSection />
            )}
          </div>
          <Dialog maxWidth='xl' open={open}>
            <PopupGuideLine onClose={() => setOpen(false)} />
          </Dialog>
        </Container>
      </div>
    </>
  );
};

export default Profile;
