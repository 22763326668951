import { Divider } from '@mui/material';
import { ManaCost } from 'components';
import { HeroType, TroopsType } from 'models/Hero';
import { isNil, path } from 'ramda';
import { currencyFormat, customizeFloatNumber } from 'utils/common';

const RoundTag = ({ value, type }: { value: string; type: 'Race' | 'Class' }) => (
  <div className='rounded-full flex justify-center items-center p-1' style={{ background: '#6E4C3C' }}>
    <img src={require(`assets/games/${type}_${value}.png`).default} className='w-4 h-4' />
  </div>
);

const TroopSection = ({ troop, heroClass }: { troop: TroopsType; heroClass: string }) => {
  const getPropOfStatsAndFormatNumber = (propName: string) => {
    const val = path<number>(['currentStats', propName], troop);
    return isNil(val) ? '-' : customizeFloatNumber(val, 1);
  };

  return (
    <div className='p-2 rounded-lg flex gap-3' style={{ background: '#432A1E' }}>
      <div className='relative flex-none' style={{ height: 'fit-content' }}>
        <img src={troop?.icon} style={{ height: 126, width: 96 }} />
        <div className='absolute bottom-1.5 left-1.5'>
          <ManaCost cost={troop?.currentStats?.cost?.toString() ?? '-'} active={true} />
        </div>
      </div>
      <div className='flex-1'>
        <div className={`font-extrabold mb-2 text-color-${troop?.rarity?.toLowerCase()}`}>{troop?.name}</div>
        <div className='flex gap-2 mb-2'>
          <div
            className='rounded-xl px-2 flex items-center text-xs font-black'
            style={{ background: '#6E4C3C' }}
          >{`Lv. ${troop?.level ?? '-'}`}</div>
          <RoundTag value={troop?.race ?? 'None'} type='Race' />
          <RoundTag value={heroClass ?? 'None'} type='Class' />
        </div>
        <Divider className='mb-2' style={{ borderBottom: '1px solid #5F4031' }} />
        <div className='grid grid-cols-2 gap-x-0.5 gap-y-2'>
          {[
            {
              icon: 'HP',
              value: getPropOfStatsAndFormatNumber('hp'),
            },
            { icon: 'ATK', value: getPropOfStatsAndFormatNumber('atk') },
            {
              icon: 'SPD',
              value: getPropOfStatsAndFormatNumber('atkSpeed'),
            },
            {
              icon: 'RAN',
              value: getPropOfStatsAndFormatNumber('atkRange'),
            },
          ].map((item, index) => (
            <div key={index} className='flex items-center gap-1'>
              <img src={require(`assets/games/Stat_${item.icon}.png`).default} className='w-4 h-4' />
              <span className='text-color-secondary' style={{ fontSize: 13 }}>
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CardTroop = ({ item }: { item: HeroType }) => {
  return (
    <div className='p-2 flex flex-col gap-4 text-color-secondary card-hover-skill w-full' style={{ background: '#51372A', borderRadius: 10 }}>
      {Object.values(item.externalAttrs?.troops ?? {}).map(
        (troop, index) =>
          !!troop && <TroopSection key={index} troop={troop} heroClass={item.externalAttrs?.class ?? 'None'} />,
      )}
      <div className='flex items-center justify-center gap-1 text-2xl text-color-secondary break-all font-bold px-1 w-full'>
        <img src={require('assets/icons/OKG-token.png').default} className='h-6' />
        {isNil(item?.sale?.price) ? '-' : currencyFormat(Number(item?.sale?.price ?? 0))}
      </div>
    </div>
  );
};

export default CardTroop;
