import { Button, Dialog, Modal, Pagination, PaginationItem, styled, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableIsRowCheck } from 'mui-datatables';
import {
  ArrowDropDown,
  ArrowDropUp,
  Circle,
  Clear,
  InfoOutlined,
  PaidOutlined,
  Replay,
  SystemUpdateAlt,
  CurrencyExchange,
} from '@mui/icons-material';
import { customizeFloatNumber, shorten } from 'utils/common';
import { Duration } from 'luxon';
import { Link } from 'react-router-dom';
import { publicRoute, scholarshipRoute } from 'routes';
import { memo, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryClient, scholarshipService } from 'services';
import { GetScholarshipByIdParams, GetScholarshipsParams, ScholarshipsHeroType } from 'models/Scholarship';
import { CardHeroMinimized } from 'views/Home/cards';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { useSnackbar } from 'notistack';
import { ScholarshipContract } from 'contracts';
import { OKG_HERO, SCHOLARSHIP_CONTRACT } from 'env';
import PopupProcessing from './PopupProcessing';
import PopupCancelScholarship from './PopupCancelScholarship';
import { UserOverviewType } from 'models/User';
import PopupTransfer from './PopupTransfer';
import PopupClaim from './PopupClaim';
import { useWindowDimensions } from 'hooks';
import PopupPayout from './PopupPayout';
import { CloseButton, DesignButton } from 'components';

export const CustomMUIDataTable = styled(MUIDataTable)`
  background: transparent !important;
  border: 1px solid #45342c;
  border-radius: 8px;
  & .tss-34eyxm-MUIDataTableToolbarSelect-root {
    /* visibility: hidden !important; */
    display: none !important;
  }
  & .MuiTableCell-root {
    border-bottom: 1px solid #45342c;
    color: #f1e9dc;
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 800px) {
      font-size: 12px;
      padding: 16px 6px !important;
      border: none !important;
      background: #271911 !important;
    }
  }
  & .MuiTableCell-head {
    border-bottom: 1px solid #45342c;
    color: #f1e9dc;
    background: #170a02cc !important;
    @media (max-width: 800px) {
      padding: 16px 6px !important;
      font-size: 12px;
      border: none !important;
    }
    :first-of-type {
      border-top-left-radius: 8px;
    }
    :last-child {
      border-top-right-radius: 8px;
    }
  }
  & .MuiTableCell-footer {
    border: none !important;
  }
  & .MuiTableCell-paddingCheckbox {
    width: auto !important;
  }
  & .Mui-selected:hover {
    background-color: #382b24 !important;
  }
  @media (max-width: 800px) {
    border-radius: 0px;
    border: none !important;
  }
`;

export const tableHeadRender = (
  columnMeta: any,
  handleToggleColumn: any,
  sortOrder: any,
  content?: () => JSX.Element,
) => {
  const sort = columnMeta.sort;
  const isSortedRow = sortOrder.name === columnMeta.name;

  return (
    <th
      key={columnMeta?.index}
      className='MuiTableCell-head p-4 text-color-primary font-black'
      style={{ height: 69.5 }}
    >
      <div className='flex items-center'>
        <div>{content ? content() : columnMeta?.label}</div>
        {sort && (
          <div className='cursor-pointer' onClick={() => handleToggleColumn(columnMeta?.index)}>
            {isSortedRow ? (
              sortOrder.direction === 'asc' ? (
                <ArrowDropUp className='block' />
              ) : (
                <ArrowDropDown className='block' />
              )
            ) : (
              <>
                <ArrowDropUp className='block -mb-3 text-lg md:text-xl' />
                <ArrowDropDown className='block text-lg md:text-xl' />
              </>
            )}
          </div>
        )}
      </div>
    </th>
  );
};

export const CustomCheckbox = (props: any) => {
  if (props.disabled) {
    return null;
  }
  if (props.checked) {
    return (
      <div className='cursor-pointer flex-none'>
        <img
          src={require('assets/icons/icon-checkbox-yes.png').default}
          onClick={(e) => props.onChange(e, false)}
          className='h-6'
        />
      </div>
    );
  } else {
    return (
      <div className='cursor-pointer flex-none'>
        <img
          src={require('assets/icons/icon-checkbox-no.png').default}
          onClick={(e) => props.onChange(e, true)}
          className='h-6'
        />
      </div>
    );
  }
};

export const NoItem = () => (
  <div
    className='py-10 flex flex-col items-center'
    style={{ border: '1px solid #45342C', borderRadius: 8, opacity: 0.5 }}
  >
    <img src={require('assets/images/no-scholarship.png').default} className='mb-4' />
    <div className='text-color-primary text-3xl font-skadi mb-2 text-center'>No scholarship available</div>
    <Link
      to={scholarshipRoute.createNew.url()}
      className='text-color-legendary text-xl font-extrabold'
      style={{ textDecoration: 'underline' }}
    >
      Start a Scholarship now
    </Link>
  </div>
);

export const statusList = [
  { code: 0, text: 'None', color: '#6C6C6C' },
  { code: 1, text: 'Pending', color: '#F1A614' },
  { code: 2, text: 'Active', color: '#3AB210' },
  { code: 3, text: 'Ending', color: '#F1A614' },
  { code: 4, text: 'Ending', color: '#F1A614' },
  { code: 5, text: 'Ended', color: '#6C6C6C' },
];

export const Status = ({ status }: { status: number }) => {
  const statusData = statusList.find((item) => item.code === status);

  return statusData ? (
    <div className='flex gap-2 items-center justify-end md:justify-start'>
      <Circle className='h-2 w-2' style={{ color: statusData.color }} /> {statusData.text}
    </div>
  ) : null;
};

const handleRowHover = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-' + rowIndex);
  if (!!row) row!.style.display = 'flex';
};

const handleRowHoverLeave = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-' + rowIndex);
  if (!!row) row!.style.display = 'none';
};

const defaultSearch = {
  currentPage: 1,
  pageSize: 10,
  full: false,
  scholarshipWeb3AddressAssigned: true,
  scholarshipStatus: '0,1,2,3,4',
};

export const ExpansionCell = ({
  title,
  value,
  evenChild,
}: {
  title: string;
  value: string | JSX.Element;
  evenChild?: boolean;
}) => (
  <div
    className='py-1 px-1.5 flex justify-between'
    style={{ ...(evenChild ? { borderRadius: 4, background: '#24160F' } : {}) }}
  >
    <div className='text-color-primary'>{title}</div>
    <div className='font-bold'>{value}</div>
  </div>
);

const TableAssigned = ({ overview }: { overview?: UserOverviewType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);
  const [isOpenPopupCancel, setIsOpenPopupCancel] = useState(false);
  const [chosenForCancel, setChosenForCancel] = useState(0);
  const [isOpenPopupSuccess, setIsOpenPopupSuccess] = useState(false);
  const [isOpenPopupTransfer, setIsOpenPopupTransfer] = useState(false);
  const [isOpenPopupClaim, setIsOpenPopupClaim] = useState(false);
  const [isOpenPopupPayout, setIsOpenPopupPayout] = useState(false);
  const [chosenForTransfer, setChosenForTransfer] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<any>([]);
  const [dataSearch, setDataSearch] = useState({
    ...defaultSearch,
    web3Address: address?.toLocaleLowerCase(),
  });

  const { data: scholarships, refetch } = useQuery(
    ['scholarshipService.getScholarshipManagerAssigned', dataSearch],
    async ({ queryKey }) => {
      const scholarshipsRes = await scholarshipService.getScholarship(queryKey[1] as GetScholarshipsParams);
      const newItems = scholarshipsRes.items.map((item, index) => ({
        ...item,
        registered: !item.assigneeUserName && !item.assigneeUserName,
      }));
      return { ...scholarshipsRes, items: newItems };
    },
    { keepPreviousData: true },
  );

  const { mutate: cancelScholarship, isLoading: isLoadingCancel } = useMutation(
    async (scholarshipId: string) => {
      await scholarshipService.cancelScholarship({ scholarshipId });
      const signature = await scholarshipService.getUnlockSignature({ scholarshipId });
      await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
        .methods.cancelScholarship(scholarshipId, signature.signature)
        .send({
          from: address,
        });
      await new Promise((resolve) => setTimeout(resolve, 6000));
      await scholarshipService.confirmUnlocked({ scholarshipId });
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar('Cancel successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        refetch();
        enqueueSnackbar(e.response?.data?.message ?? 'Cancel failed!', { variant: 'error' });
      },
    },
  );

  const { mutate: confirmCancelScholarship, isLoading: isLoadingConfirm } = useMutation(
    async (scholarshipId: string) => {
      const scholarshipContractStatus = await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
        .methods.getScolarship(scholarshipId)
        .call();
      if (scholarshipContractStatus.active) {
        const signature = await scholarshipService.getUnlockSignature({ scholarshipId });
        await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
          .methods.cancelScholarship(scholarshipId, signature.signature)
          .send({
            from: address,
          });
        await new Promise((resolve) => setTimeout(resolve, 6000));
      }
      await scholarshipService.confirmUnlocked({ scholarshipId });
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar('Cancel successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        refetch();
        enqueueSnackbar(e.response?.data?.message ?? 'Cancel failed!', { variant: 'error' });
      },
    },
  );

  const { mutate: transferGKAB, isLoading: isLoadingTransfer } = useMutation(scholarshipService.transferGKAB, {
    onSuccess: () => {
      refetch();
      setIsOpenPopupSuccess(true);
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar('Transfer successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      refetch();
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar(e.response?.data?.message ?? 'Transfer failed!', { variant: 'error' });
    },
  });

  const { mutate: claimScholarship, isLoading: isLoadingClaim } = useMutation(scholarshipService.claimScholarship, {
    onSuccess: () => {
      refetch();
      setIsOpenPopupSuccess(true);
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar('Claim successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      refetch();
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar(e.response?.data?.message ?? 'Claim failed!', { variant: 'error' });
    },
  });

  const { mutate: payoutScholarship, isLoading: isLoadingPayout } = useMutation(scholarshipService.payoutScholarship, {
    onSuccess: () => {
      refetch();
      setIsOpenPopupSuccess(true);
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar('Payout successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      refetch();
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar(e.response?.data?.message ?? 'Payout failed!', { variant: 'error' });
    },
  });

  const { mutate: retryActiveScholarship, isLoading: isLoadingRetryActive } = useMutation(
    async (body: GetScholarshipByIdParams) => {
      const scholarshipContractStatus = await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
        .methods.getScolarship(body.scholarshipId)
        .call();
      if (!scholarshipContractStatus.active) {
        const signature = await scholarshipService.getSignature({ scholarshipId: body.scholarshipId });
        await ScholarshipContract(SCHOLARSHIP_CONTRACT!)
          .methods.scholarship(
            signature.heroTokenIds,
            signature.scholarshipId,
            signature.scholarAddr,
            signature.signature,
          )
          .send({
            from: address,
          });
      }
      await scholarshipService.activateScholarship({ scholarshipId: body.scholarshipId });
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar('Edit successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        refetch();
        enqueueSnackbar(e.response?.data?.message ?? 'Edit failed!', { variant: 'error' });
      },
    },
  );

  const HoverButton = memo(({ status, rowIndex }: { status: number; rowIndex: number }) => {
    const chosenScholarship = scholarships?.items[rowIndex];
    const show = status === 1 || status === 2 || status === 3;

    return show ? (
      <div
        className='flex gap-2 h-full px-4 py-2 items-center justify-center'
        style={{
          background: isMobile ? '#271911' : '#382B24',
          minWidth: isMobile ? 'auto' : 150,
        }}
      >
        {status === 1 && (
          <Button
            variant='contained'
            startIcon={<Replay />}
            onClick={() => {
              retryActiveScholarship({
                scholarshipId: chosenScholarship?.scholarshipId ?? '',
              });
              handleRowHoverLeave(rowIndex);
            }}
          >
            Retry
          </Button>
        )}
        {status === 2 && (
          <>
            <Button
              variant='contained'
              startIcon={<PaidOutlined />}
              onClick={() => {
                setChosenForTransfer(rowIndex);
                setIsOpenPopupTransfer(true);
                handleRowHoverLeave(rowIndex);
              }}
            >
              Transfer
            </Button>
            <Button
              variant='contained'
              startIcon={<Clear />}
              onClick={() => {
                setChosenForCancel(rowIndex);
                setIsOpenPopupCancel(true);
                handleRowHoverLeave(rowIndex);
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {status === 3 && (
          <Button
            variant='contained'
            startIcon={<Replay />}
            onClick={() => {
              confirmCancelScholarship(chosenScholarship?.scholarshipId ?? '');
              handleRowHoverLeave(rowIndex);
            }}
          >
            Retry
          </Button>
        )}
      </div>
    ) : null;
  });

  const columns = useMemo(
    () => [
      {
        name: 'scholarshipId',
        label: 'ID',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            const dataIndex = currentTableData[rowIndex].index;

            return (
              <>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                  <div>{shorten(value, 8, 0)}</div>
                </Tooltip>
                <div
                  id={`hoverRow-${dataIndex}`}
                  className='absolute right-0'
                  style={{
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    display: 'none',
                  }}
                >
                  <HoverButton status={scholarships?.items[dataIndex].scholarshipStatus ?? 0} rowIndex={dataIndex} />
                </div>
              </>
            );
          },
        },
      },
      {
        name: 'userName',
        label: 'Account',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
              <div>{shorten(value, 15, 0, 15)}</div>
            </Tooltip>
          ),
        },
      },
      {
        name: 'heroes',
        label: 'Heroes',
        options: {
          filter: true,
          sort: false,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
            <div className='flex gap-2'>
              {!!value &&
                value.map((hero: ScholarshipsHeroType, index: number) => (
                  <div key={index}>
                    <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                      <CardHeroMinimized item={hero} />
                    </Link>
                  </div>
                ))}
            </div>
          ),
        },
      },
      {
        name: 'scholarWeb3Address',
        label: 'Scholar',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            const dataIndex = currentTableData[rowIndex].index;
            const scholarName = scholarships?.items[dataIndex]?.assigneeUserName ?? '';

            return (
              <div className='flex flex-col gap-1'>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{scholarName}</div>}>
                  <div>{shorten(scholarName, 15, 0, 15)}</div>
                </Tooltip>
                <div className='text-sm' style={{ color: '#94794D' }}>
                  {shorten(value)}
                </div>
              </div>
            );
          },
        },
      },
      {
        name: 'winRate',
        label: 'Win Rate',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
          ),
        },
      },
      {
        name: 'totalMatch',
        label: 'Total Matches',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
        },
      },
      {
        name: 'ratio',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                Split Rate
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>Split Rate: % to scholar - % to manager</div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{`${value}/${100 - value}`}</div>,
        },
      },
      {
        name: 'kab',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                gKAB Balance
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>
                      gKAB balance: Total available gKAB in the sub-account.
                    </div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{customizeFloatNumber(value ?? 0)}</div>,
        },
      },
      {
        name: 'payToScholar',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                Pay to scholar
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>
                      Pay to scholar: Number of tokens that manager needs to pay for scholars.
                    </div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{customizeFloatNumber(value ?? 0)}</div>,
        },
      },
      {
        name: 'scholarshipStatus',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            const dataIndex = currentTableData[rowIndex].index;

            return (
              <div className='flex flex-col gap-1'>
                <Status status={value ?? 0} />
                <div className='text-color-primary text-sm'>
                  {`${Duration.fromObject({ seconds: scholarships?.items[dataIndex].totalPlayedSeconds ?? 0 }).toFormat(
                    'h',
                  )} hours played`}
                </div>
              </div>
            );
          },
        },
      },
    ],
    [HoverButton, scholarships?.items],
  );

  const mobileColumn = useMemo(
    () => [
      {
        name: 'userName',
        label: 'Account',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            return (
              <>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                  <div>{shorten(value, 5, 0, 5)}</div>
                </Tooltip>
              </>
            );
          },
        },
      },
      {
        name: 'heroes',
        label: 'Heroes',
        options: {
          filter: true,
          sort: false,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
            <div className='flex gap-2'>
              {!!value &&
                value.map((hero: ScholarshipsHeroType, index: number) => (
                  <div key={index}>
                    <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                      <CardHeroMinimized item={hero} />
                    </Link>
                  </div>
                ))}
            </div>
          ),
        },
      },
      {
        name: 'winRate',
        label: 'Win Rate',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
          ),
        },
      },
    ],
    [],
  );

  const handleIsSelectableRow = useCallback(
    (dataIndex: number, selectedRows?: MUIDataTableIsRowCheck | undefined) => {
      let res = true;
      const scholarship = scholarships?.items[dataIndex];
      if (scholarship?.kab === 0 && scholarship?.payToScholar === 0) res = false;
      return res;
    },
    [scholarships?.items],
  );

  return (
    <>
      {!!scholarships ? (
        scholarships?.items.length === 0 ? (
          <NoItem />
        ) : (
          <>
            <div className='mb-3'>
              <div className='flex w-full gap-2 md:justify-end mb-3 px-4 md:px-0'>
                <Button
                  disabled={selectedIndex.length === 0}
                  style={{ background: '#C48A39' }}
                  variant='contained'
                  startIcon={<SystemUpdateAlt />}
                  onClick={() => {
                    setIsOpenPopupClaim(true);
                  }}
                  className='w-full md:w-auto'
                >
                  CLAIM
                </Button>
                <Button
                  disabled={selectedIndex.length === 0}
                  style={{ background: '#C46B39' }}
                  variant='contained'
                  startIcon={<CurrencyExchange />}
                  onClick={() => {
                    setIsOpenPopupPayout(true);
                  }}
                  className='w-full md:w-auto'
                >
                  PAYOUT
                </Button>
              </div>
              {isMobile ? (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={mobileColumn}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  options={{
                    rowsSelected: selectedIndex,
                    isRowSelectable: handleIsSelectableRow,
                    onRowSelectionChange: (currentSelect, allSelected) => {
                      setSelectedIndex(allSelected.map((selected) => selected.dataIndex));
                    },
                    expandableRows: true,
                    responsive: 'standard',
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    renderExpandableRow: (
                      rowData: string[],
                      rowMeta: {
                        dataIndex: number;
                        rowIndex: number;
                      },
                    ) => {
                      const expandScholarship = scholarships.items[rowMeta.dataIndex];

                      return (
                        <tr className='text-xs' style={{ background: '#1C110C' }}>
                          <td />
                          <td className='pr-12 py-2' colSpan={3}>
                            <div className='flex flex-col gap-2'>
                              <ExpansionCell
                                title='ID'
                                value={shorten(expandScholarship?.scholarshipId ?? '', 25, 0, 25)}
                              />
                              <ExpansionCell
                                title='Scholar'
                                value={shorten(expandScholarship?.assigneeUserName ?? '', 25, 0, 25)}
                                evenChild
                              />
                              <ExpansionCell
                                title={`Scholar's address`}
                                value={shorten(expandScholarship?.scholarWeb3Address ?? '', 6, 6)}
                              />
                              <ExpansionCell
                                title='Total matches'
                                value={`${expandScholarship?.totalMatch}`}
                                evenChild
                              />
                              <ExpansionCell
                                title='Split rate'
                                value={`${expandScholarship?.ratio}/${100 - expandScholarship?.ratio}`}
                              />
                              <ExpansionCell
                                title='gKAb balance'
                                value={`${customizeFloatNumber(expandScholarship?.kab ?? 0)}`}
                                evenChild
                              />
                              <ExpansionCell
                                title='Pay to scholar'
                                value={`${customizeFloatNumber(expandScholarship?.payToScholar ?? 0)}`}
                              />
                              <ExpansionCell
                                title='Status'
                                value={
                                  <div className='flex flex-col gap-1'>
                                    <Status status={expandScholarship?.scholarshipStatus ?? 0} />
                                    <div className='text-color-primary'>
                                      {`${Duration.fromObject({
                                        seconds: expandScholarship?.totalPlayedSeconds ?? 0,
                                      }).toFormat('h')} hours played`}
                                    </div>
                                  </div>
                                }
                                evenChild
                              />
                              <HoverButton
                                status={expandScholarship?.scholarshipStatus ?? 0}
                                rowIndex={rowMeta.dataIndex}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  }}
                />
              ) : (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={columns}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  options={{
                    rowsSelected: selectedIndex,
                    isRowSelectable: handleIsSelectableRow,
                    onRowSelectionChange: (currentSelect, allSelected) => {
                      setSelectedIndex(allSelected.map((selected) => selected.dataIndex));
                    },
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    setRowProps: (row, dataIndex, rowIndex) => {
                      return {
                        className: 'relative',
                        onMouseEnter: () => handleRowHover(dataIndex),
                        onMouseLeave: () => handleRowHoverLeave(dataIndex),
                      };
                    },
                  }}
                />
              )}
            </div>

            <div className='flex justify-center'>
              <Pagination
                size='large'
                color='secondary'
                page={scholarships.paginationPage}
                count={scholarships.paginationCount}
                onChange={(event, page) => {
                  if (page > 0) setDataSearch({ ...dataSearch, currentPage: page });
                }}
                renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
              />
            </div>
            <Modal
              open={
                isLoadingCancel ||
                isLoadingConfirm ||
                isLoadingRetryActive ||
                isLoadingTransfer ||
                isLoadingClaim ||
                isLoadingPayout
              }
              style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}
            >
              <>
                <PopupProcessing />
              </>
            </Modal>
            <Dialog maxWidth='md' open={isOpenPopupCancel}>
              <PopupCancelScholarship
                onClose={() => {
                  setIsOpenPopupCancel(false);
                }}
                handleClick={() => cancelScholarship(scholarships?.items[chosenForCancel].scholarshipId ?? '')}
                scholarship={scholarships?.items[chosenForCancel]}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupTransfer}>
              <PopupTransfer
                onClose={() => {
                  setIsOpenPopupTransfer(false);
                }}
                handleClick={(gKab: number) =>
                  transferGKAB({ gKab, username: scholarships?.items[chosenForTransfer].userName ?? '' })
                }
                username={scholarships?.items[chosenForTransfer].userName ?? ''}
                maxLimit={Number(customizeFloatNumber(overview?.kab ?? 0))}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupClaim}>
              <PopupClaim
                onClose={() => {
                  setIsOpenPopupClaim(false);
                }}
                handleClick={() =>
                  claimScholarship({
                    scholarshipIds: selectedIndex.map((selected: any) => scholarships.items[selected].scholarshipId),
                  })
                }
                selectedScholarship={selectedIndex.map((selected: any) => scholarships.items[selected])}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupPayout}>
              <PopupPayout
                onClose={() => {
                  setIsOpenPopupPayout(false);
                }}
                handleClick={() =>
                  payoutScholarship({
                    scholarshipIds: selectedIndex.map((selected: any) => scholarships.items[selected].scholarshipId),
                  })
                }
                selectedScholarship={selectedIndex.map((selected: any) => scholarships.items[selected])}
                maxLimit={overview?.kab ?? 0}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupSuccess}>
              <div className='flex flex-col items-center py-5 md:p-14'>
                <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
                <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>SUCCESS!</div>
                <div className='text-xs md:text-xl text-center mb-4 md:mb-10 px-5 md:px-0'>
                  Your transaction has been completed
                </div>
                <DesignButton
                  fullWidth
                  design='yellow'
                  size='large'
                  imageSize='small'
                  className='w-48'
                  onClick={() => {
                    setIsOpenPopupSuccess(false);
                  }}
                >
                  DONE
                </DesignButton>
                <CloseButton
                  onClick={() => {
                    setIsOpenPopupSuccess(false);
                  }}
                />
              </div>
            </Dialog>
          </>
        )
      ) : null}
    </>
  );
};

export default TableAssigned;
