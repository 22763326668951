import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import CocoonAbi from './abis/CocoonAbi.json';
import ERC20 from './abis/ERC20.json';
import ERC721 from './abis/ERC721.json';
import HeroAbi from './abis/HeroAbi.json';
import ManagementAbi from './abis/ManagementAbi.json';
import RedemptionAbi from './abis/RedemptionAbi.json';
import OKGMarketAbi from './abis/OKGMarket.json';
import BinanceHeroRedemptionAbi from './abis/BinanceHeroRedemption.json';
import BreedingAbi from './abis/Breeding.json';
import ClaimGiftAbi from './abis/ClaimGiftAbi.json';
import ScholarshipAbi from './abis/OKGScholarship.json';
import KABTokenAbi from './abis/KABToken.json';
import KABSwapAbi from './abis/KABSwap.json';
import OKGSwapAbi from './abis/OKGSwap.json';

export const web3 = new Web3();

export const erc20Contract = (address: string) => new web3.eth.Contract(ERC20.abi as AbiItem[], address);
export const erc721Contract = (address: string) => new web3.eth.Contract(ERC721.abi as AbiItem[], address);

export const ManagementContract = (address: string) => new web3.eth.Contract(ManagementAbi as AbiItem[], address);
export const CocoonContract = (address: string) => new web3.eth.Contract(CocoonAbi as AbiItem[], address);
export const HeroContract = (address: string) => new web3.eth.Contract(HeroAbi as AbiItem[], address);
export const RedemptionContract = (address: string) => new web3.eth.Contract(RedemptionAbi as AbiItem[], address);
export const OKGMarketContract = (address: string) => new web3.eth.Contract(OKGMarketAbi as AbiItem[], address);
export const BinanceHeroRedemptionContract = (address: string) =>
  new web3.eth.Contract(BinanceHeroRedemptionAbi as AbiItem[], address);
export const BreedingContract = (address: string) => new web3.eth.Contract(BreedingAbi as AbiItem[], address);
export const ClaimGiftContract = (address: string) => new web3.eth.Contract(ClaimGiftAbi as AbiItem[], address);
export const ScholarshipContract = (address: string) => new web3.eth.Contract(ScholarshipAbi as AbiItem[], address);
export const KABTokenContract = (address: string) => new web3.eth.Contract(KABTokenAbi as AbiItem[], address);
export const KABSwapContract = (address: string) => new web3.eth.Contract(KABSwapAbi as AbiItem[], address);
export const OKGSwapContract = (address: string) => new web3.eth.Contract(OKGSwapAbi as AbiItem[], address);

export * from './getKabTokenContract';
export * from './getOkgTokenContract';
